import { LoanApplicationService } from "../../services/LoanApplication";

export async function loader({ params, getToken, pageName }: any) {
  const { userID } = params;
  const payload = {
    userID,
    pageName,
    token: getToken(),
  };

  const response = await LoanApplicationService.listBankConnectAttempts(
    payload
  );
  console.log("responseAPI => ", response);

  return { bankStatementResponse: response?.data ?? [] };
}
