import classNames from "classnames";

interface HeadingProps {
  name: string;
  whiteLabelStyle?: any;
  classNameProp?: string;
}
export function Heading(props: HeadingProps) {
  const { name, classNameProp = "", whiteLabelStyle = {} } = props;
  return (
    <h3
      className={classNames(
        "text-sm text-gray-700 font-medium w-max border-b-2 py-2 pr-2",
        classNameProp,
        whiteLabelStyle?.theme?.color?.border || "border-indigo-500"
      )}
    >
      {name}
    </h3>
  );
}
