import Button from "../../atomic/Button";
import { notify } from "../../../shared/context/AlertContext";
import GetAuthContext from "../../../shared/context/AuthContext";
import { LoanApplicationService } from "../../../services/LoanApplication";

interface IProps {
  setOpen: Function;
  reload: Function;
  loanApplicationID: string;
  pageName: string;
}
export default function SendSignAgreementModal({
  setOpen,
  reload,
  loanApplicationID = "",
  pageName,
}: IProps) {
  const { fetchToken } = GetAuthContext();

  async function onSubmit() {
    const response = await LoanApplicationService.sendAgreementToLender({
      loanApplicationID,
      token: fetchToken(),
      pageName,
    });

    if (response.status) {
      setOpen(false);
      reload();
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  }

  return (
    <form>
      <fieldset className="flex items-center justify-end">
        <Button
          type="button"
          className="mr-4"
          onClick={() => setOpen(false)}
        >
          No
        </Button>

        <Button
          onClick={() => {
            onSubmit();
          }}
          type="button"
          color="primary"
        >
          Yes, Send Sign Agreement
        </Button>
      </fieldset>
    </form>
  );
}
