import React from "react";
import { BiCopy } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { BsCheckAll } from "react-icons/bs";
import { useOutletContext, useParams } from "react-router-dom";

import Title from "./Title";
import Confirm from "./Confirm";
import IntiateForm from "./IntiateForm";
import { DeviationProps } from "./interface";
import Button from "../../components/atomic/Button";
import CopyText from "../../components/atomic/CopyText";
import { PAGES_LIST } from "../../shared/utils/constants";
import { notify } from "../../shared/context/AlertContext";
import GetAuthContext from "../../shared/context/AuthContext";
import { getFormattedDate } from "../../shared/utils/DateUtil";
import { getFormattedCurrency } from "../../shared/utils/Global";
import { LoanApplicationService } from "../../services/LoanApplication";
import DropdownWithSearch from "../../components/atomic/DropdownWithSearch";

const L2_OPTIONS = [
  {
    name: "Approve",
    value: "accepted",
    style: "!bg-[#D1FADF] text-[#027A48] !border-[#027A48]",
  },
  {
    name: "Reject",
    value: "rejected",
    style: "!bg-[#FEE4E2] !border-[#B42318] !text-[#B42318]",
  },
];

let FIELDS = {
  // amount: {
  //   originalValue: "",
  //   label: "Loan Amount",
  //   validation: "NONNEGATIVE",
  // },
  annualInterest: {
    originalValue: "",
    validation: "ROI",
    label: "Interest Rate",
  },
  tenureMonths: {
    originalValue: "",
    validation: "MONTHS",
    label: "Tenure(Months)",
  },
  processingFee: {
    originalValue: "",
    label: "Processing Fee",
    validation: "PROCESSINGFEE",
  },
};

const KEY_NAMES = {
  amount: "loanAmount",
  tenureMonths: "tenure",
  annualInterest: "interestRate",
  processingFee: "processingFee",
};

export default function Change(props: DeviationProps) {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const { loanID } = useParams();
  const { fetchToken } = GetAuthContext();
  const [selectedAction, setSelectedAction] = React.useState({} as any);
  const loanOffer = props?.loanOfferDetails?.[0];
  const loanTermInfo = props?.timelineInfo?.[0];

  Object.keys(FIELDS ?? {})?.forEach((key: string) => {
    FIELDS[key]["originalValue"] = loanOffer[key];
    FIELDS[key]["proposedValue"] = loanTermInfo?.[KEY_NAMES[key]]?.proposed;
  });

  const onSubmitHandler = async (data: any) => {
    const payloadInfo = {
      ...loanTermInfo,
      loanApplicationID: loanID as string,
      loanOfferID: loanOffer?.loanOfferID,
      loanAmount: {
        previous: String(loanTermInfo?.loanAmount?.previous ?? ""),
        proposed: String(loanTermInfo?.loanAmount?.previous ?? ""),
        deviation: loanTermInfo?.loanAmount?.deviation,
      },
      processingFee: {
        previous: String(data?.original?.processingFee ?? ""),
        proposed: String(data?.proposed?.processingFee ?? ""),
        deviation: loanTermInfo?.processingFee?.deviation,
      },
      interestRate: {
        previous: String(data?.original?.annualInterest ?? ""),
        proposed: String(data?.proposed?.annualInterest ?? ""),
        deviation: loanTermInfo?.interestRate?.deviation,
      },
      tenure: {
        previous: String(data?.original?.tenureMonths ?? ""),
        proposed: String(data?.proposed?.tenureMonths ?? ""),
        deviation: loanTermInfo?.tenure?.deviation,
      },
      comments: data?.comments,
      requestAction: selectedAction?.value,
    };
    const payload = {
      token: fetchToken(),
      payload: payloadInfo,
      loanApplicationID: loanID as string,
      pageName: PAGES_LIST.LOAN_APPLICATION_LOAN_TERM_DEVIATION_TAB,
    };
    const response = await LoanApplicationService.saveLoanTermDeviation(
      payload
    );

    if (response?.status) {
      notify({
        type: "success",
        title: "Success",
        text: `Loan Term Deviation ${
          selectedAction?.value === "rejected" ? "Rejected" : "Approved"
        } Proposal Successfully`,
      });
      setTimeout(() => window.location.reload(), 1000);
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  return (
    <div>
      <div className="sticky top-[-40px] bg-white">
        <Title
          title="Loan Term Deviation Proposal"
          close={() => props?.setIsSliderOpen(false)}
        />
      </div>
      <div className="pb-6 border-b my-6">
        <div className="block text-xs font-medium text-gray-700 mb-2">
          Request ID
        </div>
        <CopyText
          styles={{
            container: "bg-[#FAFAFA]",
            input:
              "text-gray-800 bg-transparent w-full border border border-gray-300 rounded-lg rounded-r-none border-r-0 py-3 h-auto",
            button:
              "bg-[#FAFAFA] border border-l-0 border-gray-300 text-gray-50 h-auto w-auto px-3 py-2.5 h-[46px]",
          }}
          text={loanTermInfo?.requestID ?? ""}
          copyButton={<BiCopy className="h-5 w-5 text-[#777878]" />}
          copyingButton={<BsCheckAll className="h-5 w-5 text-[#027A48]" />}
        />
      </div>
      <div className="flex items-center justify-between">
        <div className="text-xs text-medium">
          <div>
            <span className="text-[#626262]">Updated by</span>
            <span className="text-[#777878] ml-1">
              {loanTermInfo?.actionBy}
            </span>
          </div>
          <div className="">
            <span className="text-[#626262]">Updated on </span>
            <span className="text-[#777878] ml-1">
              {getFormattedDate(loanTermInfo?.createdAt) ?? "-"}
            </span>
          </div>
        </div>
        <Button
          outline
          color="primary"
          onClick={() => {
            props?.setCurrentSlider("TIMELINE", true, props?.timelineInfo);
          }}
          className=" !bg-white border-none"
        >
          View Timeline
        </Button>
      </div>
      <div className="my-6 pb-6 border-b">
        <div className="text-sm text-[#626262] font-medium">
          General Comment
        </div>
        <div className="mt-1 text-xs font-normal text-[#777878]">
          {loanTermInfo?.comments || "-"}
        </div>
      </div>
      <form
        className="mt-5"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div>
          <div className="flex justify-between items-center border-b pb-5 mb-5">
            <div className="text-sm font-semibold text-[#626262]">
              Loan Amount
            </div>
            <div className="text-xl font-semibold">
              {/* {getFormattedCurrency(loanTermInfo?.loanAmount?.previous)} */}
              {getFormattedCurrency(loanOffer?.amount ?? 0)}
            </div>
          </div>
          <IntiateForm
            errors={errors}
            fields={FIELDS}
            isValid={isValid}
            register={register}
            loanOffer={loanOffer}
            getValues={getValues}
            textArea={{ label: "General Comments", name: "comments" }}
          />
        </div>
        <div className="mt-6 text-[#4C4C4D] text-xs font-normal">
          These actions will affect the entire proposal including all Loan terms
        </div>
        <div className="mt-2 flex justify-between items-center">
          <DropdownWithSearch
            multiple={false}
            hasSearch={false}
            mode="reverse"
            options={L2_OPTIONS}
            styles={{
              container: `!w-56`,
              button: selectedAction?.style,
              dropdown: "shadow-[-2px_-2px_15px_-3px_rgba(0,0,0,0.5)]",
            }}
            onChange={(action: any) => {
              setSelectedAction(action);
            }}
            selectedItems={selectedAction}
            placeholder="Action on Proposal"
          />
          <Confirm action={selectedAction?.value} />
        </div>
      </form>
    </div>
  );
}
