import { LoanApplicationService } from "../../services/LoanApplication";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export async function CoApplicantLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID } = params;
  const payload = {
    pageName,
    token: getToken(),
    loanApplicationID: loanID as string,
  };
  const response = (await LoanApplicationService.getCoApplicants(
    payload
  )) as any;

  return { coApplicantResponse: response?.data ?? {} };
}
