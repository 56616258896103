import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import { Fragment, ReactNode } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function PopoverPanel({
  icon,
  styles,
  children,
  buttonText,
  onClick,
  tooltipContent,
  closeAction = true,
  showArrow = true,
}: {
  icon?: ReactNode;
  buttonText?: string;
  children: ReactNode;
  tooltipContent?: string;
  showArrow?: boolean;
  closeAction?: boolean;
  onClick?: () => void;
  styles?: {
    button?: string;
    arrow?: string;
    panel?: string;
    buttonText?: string;
    container?: string;
    childContainer?: string;
  };
}) {
  return (
    <div className={classNames("w-full", styles?.container || "")}>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              data-tooltip-id={tooltipContent ? "popoverButton" : undefined}
              data-tooltip-content={tooltipContent ?? ""}
              onClick={() => onClick?.()}
              className={classNames(
                "group flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-opacity-75",
                open ? "" : "text-opacity-90",
                styles?.button || ""
              )}
            >
              {icon ? (
                icon
              ) : (
                <>
                  <span className={styles?.buttonText}>{buttonText}</span>
                  {!!showArrow && (
                    <ChevronDownIcon
                      aria-hidden="true"
                      className={classNames(
                        "ml-2 h-5 w-5 text-indigo-100 transition duration-150 ease-in-out group-hover:text-opacity-80",
                        open ? "" : "text-opacity-70",
                        styles?.arrow || ""
                      )}
                    />
                  )}
                </>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              leaveTo="opacity-0 translate-y-1"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leaveFrom="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              enter="transition ease-out duration-200"
            >
              <Popover.Panel
                className={classNames(
                  "absolute left-1/2 z-10 mt-3  -translate-x-1/2 transform px-4 sm:px-0 max-w-xs",
                  styles?.panel || ""
                )}
              >
                {({ close }) => (
                  <div
                    className={classNames(
                      "overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5",
                      styles?.childContainer || ""
                    )}
                    onClick={() => {
                      if (closeAction) {
                        close();
                      }
                    }}
                  >
                    {children}
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      {tooltipContent && (
        <Tooltip
          id="popoverButton"
          className="!bg-[#212121] !text-xs !font-semibold !rounded-lg"
        />
      )}
    </div>
  );
}
