import Button from "../../atomic/Button";
import { notify } from "../../../shared/context/AlertContext";
import GetAuthContext from "../../../shared/context/AuthContext";
import GetMixpanelContext from "../../../shared/context/MixpanelContext";
import { LoanApplicationService } from "../../../services/LoanApplication";

interface IProps {
  setOpen: Function;
  reload: Function;
  loanApplicationID: string;
  pageName: string;
}
export default function ApproveKYCModal({
  loanApplicationID,
  setOpen,
  reload,
  pageName,
}: IProps) {
  const { fetchToken } = GetAuthContext();
  const { trackUserAction } = GetMixpanelContext();

  async function onSubmit(isDoubleName: boolean) {
    const response = await LoanApplicationService.approveLoanKYC({
      loanApplicationID,
      isDoubleName,
      token: fetchToken(),
      pageName,
    });

    if (response.status) {
      trackUserAction("approve_kyc", {
        loanApplicationID,
        isDoubleName,
      });
      setOpen(false);
      reload();
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  }

  return (
    <div>
      <form>
        <fieldset className="flex items-center justify-end">
          <Button
            type="button"
            color="primary"
            className="mr-2"
            onClick={() => onSubmit(true)}
          >
            Approve with Dual name Indemnity
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={() => onSubmit(false)}
          >
            Approve
          </Button>
        </fieldset>
      </form>
    </div>
  );
}
