import React from "react";
import { useOutletContext } from "react-router";

import View from "./View";
import { Card } from "./Card";
import { DirectorType, DirectorsProps } from "./interface";
import { CollapsibleSidebar } from "../../components/atomic/CollapsibleSidebar";

export const arrangeDirectors = (directorDetails: DirectorType[]) => {
  return (
    directorDetails?.sort((a, b) => {
      if (a.active === b.active) {
        if (a.status === "QUALIFIED" && b.status !== "QUALIFIED") {
          return -1;
        } else if (a.status !== "QUALIFIED" && b.status === "QUALIFIED") {
          return 1;
        } else {
          return 0;
        }
      } else {
        return a.active ? -1 : 1;
      }
    }) ?? []
  );
};

export default function Directors({ pageName }: DirectorsProps) {
  const [sidebar, setSidebar] = React.useState<boolean>(false);
  const [currentDirector, setCurrentDirector] = React.useState(
    {} as DirectorType
  );
  const { loanDetails } = useOutletContext() as any;

  const setViewInfo = (data: DirectorType) => {
    setCurrentDirector(data);
    setSidebar(true);
  };

  const close = () => {
    setSidebar(false);
    setCurrentDirector({} as DirectorType);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="font-medium text-lg text-[#4C4C4D]">
          {loanDetails?.constitution === "Partnership"
            ? "Partners "
            : "Directors "}
          List
        </div>
      </div>

      {/* page vieww */}
      {arrangeDirectors(loanDetails?.directorDetails)?.map(
        (director: DirectorType, index: number) => {
          return (
            <Card
              key={index}
              pageName={pageName}
              director={director}
              setViewInfo={setViewInfo}
            />
          );
        }
      )}

      <CollapsibleSidebar
        show={sidebar}
        setShow={setSidebar}
        style={{ container: "!p-0" }}
      >
        <View
          close={close}
          pageName={pageName}
          currentDirector={currentDirector}
        />
      </CollapsibleSidebar>
    </div>
  );
}
