import { format } from "date-fns";
import classNames from "classnames";
import { useOutletContext } from "react-router";
import { XMarkIcon } from "@heroicons/react/24/solid";

import { GENDER, STATUS } from "./Card";
import { VIEW_STRUCTURE } from "./constant";
import Button from "../../components/atomic/Button";
import {
  DropdownValue,
  ViewItemProps,
  SidebarContainerProps,
} from "./interface";

export default function View(props: SidebarContainerProps) {
  const { currentDirector, close } = props;
  const { loanDetails } = useOutletContext() as any;

  const formatValue = (key: string, value: any) => {
    switch (key) {
      case "gender":
        return GENDER[value];
      case "shareHoldingPercentage":
        return `${value}%`;
      case "isDSCRegistered":
        return value ? "Yes" : "No";
      case "dob":
      case "dateOfAppointment":
      case "expiryDate":
        return value ? format(new Date(value), "dd MMM, yyyy") : "-";
      default:
        return value;
    }
  };

  const values = Object.keys(VIEW_STRUCTURE)?.reduce(
    (operation, current: string) => {
      const items = VIEW_STRUCTURE[current];
      const info = items?.map((item) => {
        const value = formatValue(item.key, currentDirector?.[item.key]);
        return {
          ...item,
          value: value || "-",
        };
      });
      return { ...operation, [current]: info };
    },
    {}
  );

  return (
    <div className="p-10">
      <div className="flex items-center justify-between bg-white">
        <div className="text-[#343F59] font-medium text-xl">
          {loanDetails?.constitution === "Partnership"
            ? "Partners "
            : "Directors "}
          details
        </div>
        <div className="flex items-center">
          <Button
            onClick={close}
            className="!p-1.5 hover:!bg-[#FAFAFA] !bg-transparent transition ease-in-out ml-3"
          >
            <XMarkIcon className="h-5 w-5 text-[#4C4C4D]" />
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <div className="flex items-center">
          {currentDirector?.status ? (
            <div
              className={classNames(
                "text-xs font-normal px-2 py-1 capitalize rounded-full",
                STATUS?.[currentDirector?.status]?.class
              )}
            >
              {STATUS?.[currentDirector?.status]?.text}
            </div>
          ) : null}
          {currentDirector?.active && (
            <div
              className={classNames(
                " bg-[#DEEAF8] text-[#1982FF] text-xs font-normal px-2 py-1 rounded-full",
                currentDirector.status ? "ml-1" : ""
              )}
            >
              Included in the loan application
            </div>
          )}
        </div>
        <div className="text-[#343F59] font-bold text-3xl mt-1">
          {currentDirector?.name}
        </div>
      </div>

      <ViewItem
        title="General Details"
        items={values?.generalDetails ?? []}
      />
      <ViewItem
        title={
          loanDetails?.constitution === "Partnership"
            ? "Partners Details"
            : "Directors Details"
        }
        items={values?.directorDetails ?? []}
      />
      <ViewItem
        title="Address Details"
        items={values?.addressDetails ?? []}
      />
    </div>
  );
}

const ViewItem = (props: ViewItemProps) => {
  const { title, items = [] } = props;
  return (
    <div className="mt-8">
      <div className="text-[#626262] font-bold text-base relative">
        <span className="bg-white absolute z-1 -top-3 pr-5">{title}</span>
        <hr className="!border-[#E3E4E5]" />
      </div>
      <div className="grid grid-cols-2 gap-5 mt-6">
        {items?.map((item: DropdownValue, index: number) => (
          <div key={index}>
            <div className="text-[#373737] text-sm font-medium">
              {item?.title}
            </div>
            <div className="mt-1 text-[#626262] font-medium text-sm">
              {item?.value ?? "-"}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
