export const VIEW_STRUCTURE = {
  generalDetails: [
    {
      key: "source",
      title: "Source",
      value: "",
    },
    {
      key: "designation",
      title: "Designation",
      value: "",
    },
    {
      key: "pan",
      title: "PAN number",
      value: "",
    },
    {
      key: "shareHoldingPercentage",
      title: "Shareholding Percentage",
      value: "",
    },
    {
      key: "email",
      title: "Email",
      value: "",
    },
    {
      key: "dob",
      title: "Date of birth",
      value: "",
    },
    {
      key: "gender",
      title: "Gender",
      value: "",
    },
    {
      key: "mobile",
      title: "Contact number",
      value: "",
    },
    {
      key: "cifNumberIndividual",
      title: "Customer Number Individual",
      value: "",
    },
  ],
  directorDetails: [
    {
      key: "identificationNumber",
      title: "Identification Number",
      value: "",
    },
    {
      key: "isDSCRegistered",
      title: "Is DSC Registered ",
      value: "",
    },
    {
      key: "dateOfAppointment",
      title: "Date of Appointment",
      value: "",
    },
    {
      key: "expiryDate",
      title: "Date Of Expiry",
      value: "",
    },
  ],
  addressDetails: [
    {
      key: "line1",
      title: "Address line 1",
      value: "",
    },
    {
      key: "line2",
      title: "Address line 2",
      value: "",
    },
    {
      key: "city",
      title: "City",
      value: "",
    },
    {
      key: "state",
      title: "State",
      value: "",
    },
    {
      key: "pincode",
      title: "Pincode",
      value: "",
    },
    {
      key: "residentType",
      title: "Resident Type",
      value: "",
    },
  ],
};
