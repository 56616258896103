import { LoanApplicationService } from "../../services/LoanApplication";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export default async function loanBankInfoLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID, sourceEntityID } = params;

  return await LoanApplicationService.getBankDetails({
    pageName,
    token: getToken(),
    loanApplicationID: loanID as string,
    sourceEntityID: sourceEntityID as string,
  });
}
