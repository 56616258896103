import React from "react";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import { useOutletContext } from "react-router";
import { EventHandler, MouseEvent, ReactNode } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { TimelineObject } from "./interface";
import { humanize } from "../../../shared/utils/Common";
import { camelToHuman, getFormattedDate } from "../../../shared/utils/DateUtil";

interface IProps {
  timeline: Array<TimelineObject>;
  onViewMoreClick: EventHandler<MouseEvent>;
  hasMorePages?: boolean;
  page: number;
}

export const getEventDesc = (desc: string) => {
  if (desc) {
    try {
      const json = JSON.parse(desc);
      const timelineInfo = Array.isArray(json) ? json : [json] ?? [];
      if (timelineInfo) {
        return (
          <span className="text-xs">
            {timelineInfo?.map((info, index) => (
              <React.Fragment key={index}>
                {Object.keys(info ?? {}).map((value, inx) => {
                  return (
                    <React.Fragment key={inx}>
                      <span>
                        <>
                          {camelToHuman(value)}
                          {" : "}
                          {info[value]}
                        </>
                      </span>
                      <br />
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            ))}
          </span>
        );
      } else return <span className="text-xs">{desc}</span>;
    } catch {
      if (desc.includes("</a>"))
        return (
          <span
            className="text-xs"
            dangerouslySetInnerHTML={{
              __html: desc,
            }}
          />
        );
      return <span className="text-xs">{desc}</span>;
    }
  }
  return "";
};

const getNameMismatchText = (eventType: string) => {
  if (eventType === "bank_connect_failed") {
    return `1. Please download bank statement and check type of statement uploaded.<br />
      2. If user has added savings account: Bank info name and bank statement name have to be same<br />
      3. If user has added current account: Firm name, bank info name, business details name have to be same`;
  }
  if (eventType === "bank_verification_failed") {
    return `1. Please check type of bank account added<br />
      2. If user has added current account: Bank info name and business details name have to be same<br />
      3. If user has added savings account: Bank info name and applicant name have to be same`;
  }
  return "";
};

export default function ActivityTimeline({
  timeline,
  onViewMoreClick,
  hasMorePages = true,
  page = 1,
}: IProps): JSX.Element {
  const outletContxt = useOutletContext() as any;
  const { whiteLabelStyle } = outletContxt ?? {};
  return (
    <>
      <div className="mt-6 flow-root">
        <ul
          role="list"
          className="-mb-8"
        >
          {timeline?.map((item, itemIdx) => {
            let title: ReactNode = `${humanize(item.eventType)} - ${humanize(
              item.entityType
            )}${item.entityRef ? " - (" + item.entityRef + ")" : ""}`;

            title =
              ["bank_verification_failed", "bank_connect_failed"].includes(
                item?.eventType
              ) &&
              item?.eventDescription &&
              item.eventDescription.includes("name") ? (
                <div className="mt-2 flex items-center justify-start">
                  {title + " - "}
                  <div
                    className="tooltip"
                    data-tooltip-html={getNameMismatchText(item.eventType)}
                  >
                    <InformationCircleIcon className="text-red-500 w-4 h-4 ml-2" />
                  </div>
                </div>
              ) : (
                title
              );

            return (
              <li
                key={itemIdx}
                className="overflow-hidden"
              >
                <div className="relative pb-8">
                  {itemIdx !== timeline.length - 1 ? (
                    <span
                      className="absolute top-12 left-4 h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex mb-4">
                    <div className="pt-3">
                      <span
                        className={classNames(
                          "flex items-center justify-center h-8 w-8  rounded-full border ",
                          whiteLabelStyle?.theme?.color?.lightbg ||
                            "bg-blue-100 border-blue-100",
                          item.bgClassName
                        )}
                      >
                        <span
                          className={classNames(
                            "block h-3 w-3 rounded-full",
                            whiteLabelStyle?.theme?.color?.darkbg ||
                              "bg-indigo-800"
                          )}
                        />
                        {item.icon}
                      </span>
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between pt-3 ml-6">
                      <div>
                        <p className="text-xs text-gray-700 font-medium mb-2">
                          {title}
                        </p>
                        <p className="text-xs text-gray-400 mb-2">
                          {getEventDesc(item.eventDescription)}
                        </p>
                        <div className="flex items-center mb-2 text-gray-400">
                          <p className="text-xs ">
                            {getFormattedDate(item.loggedAt, true)}
                          </p>
                        </div>
                      </div>
                      <div className="whitespace-nowrap text-right text-xs text-gray-500">
                        {/*<time dateTime={item.datetime}>{item.date}</time>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          }) ?? null}
        </ul>
      </div>
      <Tooltip anchorSelect=".tooltip" />
      {hasMorePages && (
        <div className="justify-stretch mt-8 flex flex-col">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-xs text-gray-500 font-medium"
            onClick={onViewMoreClick}
          >
            View More
          </button>
        </div>
      )}

      {!hasMorePages && page > 1 ? (
        <p className="text-xs text-gray-500 pt-16 text-center">
          Nothing more to show
        </p>
      ) : null}
    </>
  );
}
