import { useLoaderData, useOutletContext, useParams } from "react-router-dom";

import { PageProps } from "../../services/Common/interfaces";
import NotesTab from "../../../unified-dashboard/src/pages/NotesTab";
import { LoanApplicationService } from "../../services/LoanApplication";

export async function loanNotesLoader({ params, getToken, pageName }: any) {
  const { loanID } = params;
  const payload = { token: getToken(), pageName, loanApplicationID: loanID };
  const response = await LoanApplicationService.getNotes(payload);
  return {
    notesResponse: response,
  };
}

function LoanNotes({ pageName }: PageProps) {
  const { loanID, sourceEntityID } = useParams();
  const { notesResponse } = useLoaderData() as any;
  const { whiteLabelStyle }: any = useOutletContext();

  const HREF = `/loan-applications/details/${sourceEntityID}/${loanID}/notes`;
  return (
    <NotesTab
      href={HREF}
      whiteLabelStyle={whiteLabelStyle}
      notes={notesResponse?.data?.notes ?? []}
    />
  );
}

export default LoanNotes;
