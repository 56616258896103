import { CardLabelProps } from "../interface";

export const CardLabel = (props: CardLabelProps) => {
  const { label, icon, value } = props;
  return (
    <div className="flex">
      {icon}
      <div className="font-medium text-sm ml-3">
        <div className="text-[#373737]">{label}</div>
        <div className="text-[#626262] mt-1">{value}</div>
      </div>
    </div>
  );
};
