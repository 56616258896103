export const WORKFLOWS_WITH_DOCS = [
  {
    name: "KYC - RCU Document Request",
    actions: ["upload_additional_documents"],
    status: ["rad", "rad_and_vkyc"],
    workflowName: "abfl_kyc_wf",
    key: "upload_additional_documents",
  },
  {
    name: "KYC - RCU Document Request",
    actions: ["upload_additional_documents"],
    status: ["processing"],
    workflowName: "abfl_kyc_wf_v2",
    key: "upload_additional_documents",
    targetGroup: "RCU",
  },
  {
    name: "Manual Credit Review Workflow - Credit L1",
    workflowName: "abfl_bre_wf",
    status: ["processing"],
    actions: ["Credit L1"],
    key: "Credit L1",
  },
  {
    name: "Manual Credit Review Workflow - RCU",
    workflowName: "abfl_bre_wf",
    status: ["processing"],
    actions: ["RCU"],
    key: "RCU",
  },
  {
    name: "Manual Credit Review Workflow - Sales",
    workflowName: "abfl_bre_wf",
    status: ["processing"],
    actions: ["Sales"],
    key: "Sales",
  },
  {
    name: "Manual Credit Review Workflow - Sales",
    workflowName: "abfl_bre_wf_v2",
    status: ["processing"],
    actions: ["Sales"],
    key: "Sales",
  },
  {
    name: "Manual Credit Review Workflow - Credit L1",
    workflowName: "abfl_bre_wf_v2",
    status: ["processing"],
    actions: ["Credit L1"],
    key: "Credit L1",
  },
  {
    name: "Manual Credit Review Workflow - RCU",
    workflowName: "abfl_bre_wf_v2",
    status: ["processing"],
    actions: ["RCU"],
    key: "RCU",
  },
];

