import CopyText from "../../atomic/CopyText";

interface IProps {
  webLink?: string;
  setOpen: React.Dispatch<boolean>;
}

export default function GenerateLinkModal({
  setOpen,
  webLink,
}: IProps): JSX.Element {
  return (
    <div>
      <div className="py-4">
        <CopyText
          styles={{
            container: "bg-gray-100",
            input:
              "text-gray-800 bg-transparent w-full border border-dashed border-gray-300 rounded-lg rounded-r-none border-r-0 py-3 h-auto",
            button:
              "bg-indigo-500 border border-indigo-300 text-gray-50 py-4 h-auto",
          }}
          text={webLink || ""}
        />
      </div>
    </div>
  );
}
