import { Tab } from "../../components/atomic/Tabs/interface";

export const MOBILE_LOAN_DETAILS_SECTION = {
  level_1: [
    {
      label: "Name",
      field: "name",
    },
    {
      label: "Customer ID",
      field: "customerID",
    },
  ],
  level_2: [
    {
      label: "Mobile",
      field: "mobile",
    },
    {
      label: "Email",
      field: "email",
    },
  ],
  level_3: [
    {
      label: "PAN",
      field: "pan",
    },
    {
      label: "Status",
      field: "status",
    },
  ],
  level_4: [
    {
      label: "DOB",
      field: "dob",
    },
    {
      label: "Gender",
      field: "gender",
    },
  ],
  level_5: [
    {
      label: "Firm Name",
      field: "firmName",
    },
    {
      label: "Constitution",
      field: "constitution",
    },
  ],
  level_6: [
    {
      label: "Business Address",
      field: "businessAddress",
    },
    {
      label: "Loan Application ID",
      field: "loanApplicationID",
    },
  ],
};

export const LOAN_DETAIL_FIELDS = {
  loanType: {
    label: "Loan Type",
    allowCopy: false,
    category: "loan-details",
  },
  loanApplicationNum: {
    label: "Loan ID",
    allowCopy: false,
    category: "loan-details",
  },
  customerID: {
    label: "Customer ID",
    allowCopy: false,
    category: "loan-details",
  },
  sourceEntityName: {
    label: "Partner Name",
    allowCopy: false,
    category: "loan-details",
  },
  utr: {
    label: "Loan Account Number",
    allowCopy: false,
    category: "loan-details",
    isExcludeEmpty: true,
  },
  crmID: {
    label: "CRM ID",
    allowCopy: false,
    category: "loan-details",
    isExcludeEmpty: true,
  },
  crmApplicationStatus: {
    label: "CRM Application Status",
    allowCopy: false,
    category: "loan-details",
    isExcludeEmpty: true,
  },
  mobile: {
    label: "Mobile",
    allowCopy: false,
    category: "communication",
  },
  agentCode: {
    label: "Agent Code",
    allowCopy: false,
    category: "additional-info",
  },
  dsaName: {
    label: "Agent Name",
    allowCopy: false,
    category: "additional-info",
  },
  dsaEmail: {
    label: "Agent Email",
    allowCopy: false,
    category: "communication",
  },
  dsaMobile: {
    label: "Agent Mobile",
    allowCopy: false,
    category: "communication",
  },
  cibil: {
    label: "CIBIL Score",
    allowCopy: false,
    category: "additional-info",
  },
  cibilStatus: {
    label: "CIBIL Status",
    allowCopy: false,
    category: "additional-info",
  },
  cibilHardPull: {
    allowCopy: false,
    key: "CIBIL_HARD_PULL",
    label: "Hard Pull CIBIL Score",
    category: "additional-info",
  },
  experian: {
    label: "Experian Score",
    allowCopy: false,
    category: "additional-info",
  },
  experianStatus: {
    label: "Experian Status",
    allowCopy: false,
    category: "additional-info",
  },
  fis: {
    label: "FIS Score",
    allowCopy: false,
    category: "additional-info",
  },
  createdAt: {
    label: "Created At",
    allowCopy: false,
    category: "loan-details",
  },
  updatedAt: {
    label: "Updated At",
    allowCopy: false,
    category: "loan-details",
  },
  source: {
    label: "Source",
    allowCopy: false,
    category: "additional-info",
  },
  name: {
    label: "Name",
    allowCopy: false,
    category: "general",
  },
  maritalStatus: {
    label: "Marital Status",
    allowCopy: false,
    category: "personal",
  },
  educationLevel: {
    label: "Education",
    allowCopy: false,
    category: "personal",
  },
  fathersName: {
    label: "Father's Name",
    allowCopy: false,
    category: "personal",
  },
  loanPurpose: {
    label: "Loan Purpose",
    allowCopy: false,
    category: "general",
  },
  address: {
    label: "Address",
    allowCopy: false,
    category: "personal",
  },
  residenceType: {
    label: "Residence Type",
    allowCopy: false,
    category: "personal",
  },
  dob: {
    label: "DOB",
    allowCopy: false,
    category: "personal",
  },
  loanAccountNumber: {
    label: "Loan Account Number",
    allowCopy: false,
    category: "loan-details",
  },
  reqLoanAmount: {
    label: "Requested Loan Amount",
    allowCopy: false,
    category: "loan-details",
  },
  processingFee: {
    label: "Processing Fee",
    allowCopy: false,
    category: "loan-details",
  },
  radiusFlagResult: {
    label: "Radius Flag Result",
    allowCopy: false,
    category: "additional-info",
  },
  radiusValueKms: {
    label: "Radius Value in KMs",
    allowCopy: false,
    category: "additional-info",
  },
  distanceBlocking: {
    label: "Distance Blocking",
    allowCopy: false,
    category: "additional-info",
  },
  campaignType: {
    label: "Campaign Type",
    allowCopy: false,
    category: "additional-info",
  },
  utmSource: {
    label: "UTM Source",
    allowCopy: false,
    category: "additional-info",
  },
  utmContent: {
    label: "UTM Content",
    allowCopy: false,
    category: "additional-info",
  },
  utmMedium: {
    label: "UTM Medium",
    allowCopy: false,
    category: "additional-info",
  },
  utmCampaign: {
    label: "UTM Campaign",
    allowCopy: false,
    category: "additional-info",
  },
};

export const LOAN_APPLICATION_TABLE_COLUMNS = [
  {
    key: "name",
    name: "Name",
    showInMobile: true,
    width: "180px",
    headerStyles: "",
    rowStyles: "",
  },
  {
    key: "loanApplicationNum",
    name: "Loan Application ID",
    showInMobile: true,
    width: "180px",
    headerStyles: "",
    rowStyles: "",
  },
  {
    key: "lenderName",
    name: "Lender",
    showInMobile: true,
    width: "180px",
    headerStyles: "",
    rowStyles: "",
  },
  {
    key: "mobile",
    name: "Mobile",
    showInMobile: true,
    width: "120px",
    headerStyles: "",
    rowStyles: "",
  },
  {
    key: "status",
    name: "Status",
    showInMobile: true,
    width: "150px",
    headerStyles: "!text-center",
    rowStyles: "",
  },
  {
    key: "sourceEntityName",
    name: "Platform",
    showInMobile: true,
    width: "150px",
    headerStyles: "",
    rowStyles: "",
  },
  {
    key: "updatedAt",
    name: "Updated At",
    isDefault: true,
    width: "150px",
    headerStyles: "",
    rowStyles: "",
  },
  {
    key: "createdAt",
    name: "Created At",
    isDefault: false,
    showInMobile: true,
    width: "150px",
    headerStyles: "",
    rowStyles: "",
  },
  {
    key: "firmName",
    name: "Firm",
    isDefault: false,
    width: "150px",
    headerStyles: "",
    rowStyles: "",
  },
  {
    key: "agentCode",
    name: "Agent Code",
    isDefault: false,
    width: "150px",
    headerStyles: "",
    rowStyles: "",
  },
  {
    key: "disbursedDate",
    name: "Disbursed Date",
    isDefault: false,
    showInMobile: false,
    width: "150px",
    headerStyles: "",
    rowStyles: "",
  },
  {
    key: "source",
    name: "Source",
    isDefault: false,
    showInMobile: false,
    width: "120px",
    headerStyles: "",
    rowStyles: "",
  },
];

export const LOAN_APPLICATION_SEARCH_TYPES = [
  {
    name: "Mobile",
    value: "mobile",
  },
  {
    name: "Name",
    value: "name",
  },
  {
    name: "Customer ID",
    value: "customerID",
  },
  {
    name: "Loan Application ID",
    value: "loanApplicationNum",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Partner Code",
    value: "partnerCode",
  },
  {
    name: "Source",
    value: "source",
  },
  {
    name: "PAN",
    value: "pan",
  },
  {
    name: "Firm Name",
    value: "firmName",
  },
  {
    name: "CRM ID",
    value: "crmID",
  },
];

export const LOAN_APPLICATION_SEARCH_KEYS = LOAN_APPLICATION_SEARCH_TYPES.map(
  (type) => type.value
);

export const MOBILE_LOAN_DETAILS_SECTION_LINKS = [
  {
    label: "Loan Offer",
    href: "loan-offer",
  },
  {
    label: "Bank Info",
    href: "bank-info",
  },
  {
    label: "Payment Details",
    href: "payments",
  },
];

export const LOAN_CANCELLATION_REASONS = [
  {
    name: "Customer no longer interested",
    value: "customer no longer interested",
  },
  {
    name: "Other",
    value: "other",
  },
];

export const LOAN_APPLICATION_DETAILS_TABS = {
  Details: {
    name: "Details",
    href: "/",
    current: true,
  },
  "Loan Term Deviation": {
    name: "Loan Term Deviation",
    href: "/loan-term-deviation",
    current: true,
  },
  "Case Status": {
    name: "Case Status",
    href: "/sales-disposition",
    current: true,
  },
  "Activity History": {
    name: "Timeline",
    href: "/timeline",
    current: true,
  },
  "Workflow Timeline": {
    name: "Workflow Timeline",
    href: "/workflow-timeline",
    current: false,
  },
  "CreditLine Details": {
    name: "CreditLine Details",
    href: "/creditline-details",
    current: true,
  },
  "CreditLine Transactions": {
    name: "CreditLine Transactions",
    href: "/creditline-transactions",
    current: true,
  },
  "Application Form": {
    name: "Application Form",
    href: "/application-form",
    current: false,
  },
  KYC: {
    name: "KYC",
    href: "/kyc",
    current: false,
  },
  "Co-Applicants": {
    name: "Co-Applicants",
    href: "/co-applicants",
    current: false,
  },
  Directors: {
    name: "Directors",
    href: "/directors",
    current: false,
  },
  "Loan Offer": {
    name: "Loan Offer",
    href: "/loan-offer",
    current: false,
  },
  "Bank Info": {
    name: "Bank Info",
    href: "/bank-info",
    current: false,
  },
  "Partner Details": {
    name: "Partner Details",
    href: "/partner-details",
    current: false,
  },
  Payments: {
    name: "Payments",
    href: "/payments",
    current: false,
  },
  "Bank Statements": {
    name: "Bank Statements",
    href: "/bank-statements",
    current: false,
  },
  "Business Details": {
    name: "Business Details",
    href: "/business-details",
    current: false,
  },
  "Additional Docs": {
    name: "Additional Docs",
    href: "/additional-docs",
    current: false,
  },
  Notes: {
    name: "Notes",
    href: "/notes",
    current: false,
  },
} as const;

export const GENDER_OPTIONS = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const BUSINESS_ADDRESS_TYPE_OPTIONS = [
  {
    label: "Owned",
    value: "Owned",
  },
  {
    label: "Rented",
    value: "Rented",
  },
];

const incomeRangeList = [
  "₹0 - ₹3 L",
  "₹3 L - ₹12 L",
  "₹12 L - ₹30 L",
  "₹30 L - ₹60 L",
  " ₹60 L - ₹1 C",
  "₹1 - ₹10 C",
  "More than ₹10 C",
];

export const LAST_12_MONTHS_SALE_OPTIONS = incomeRangeList.map((item) => ({
  label: item,
  value: item,
}));

const stateList = [
  "Andaman & Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra & Nagar Haveli",
  "Daman & Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu & Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
];

export const STATES_LIST = stateList.map((state) => ({
  name: state,
}));

export const KYC_DOCUMENT_REJECTION_REASONS = [
  {
    value: "Document Not Clear",
    label: "Document Not Clear",
  },
  {
    value: "Fraud Document",
    label: "Fraud Document",
  },
  {
    value: "Not A Valid Document",
    label: "Not A Valid Document",
  },
  {
    value: "Other",
    label: "Other",
  },
];
