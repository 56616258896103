import { format } from "date-fns";
import { LeadResponse, LeadsParams } from "../../pages/Leads/interfaces";
import { Get, Post } from "../../shared/services/Fetch";
import { noHeadersResponse } from "../../shared/utils/Global";
import { ServiceMethodArgs } from "../LoanApplication/interfaces";

export class LeadsService {

  static async getUsersList({
    token,
    limit = 10,
    page = 1,
    statusText,
    searchType,
    searchQuery,
    filters = {},
    productType = "",
    sourceEntityIDs = [],
    dateFilter,
    to = null,
    from = null,
    constitution = "",
    pageName,
    dsaIDs = [],
  }: ServiceMethodArgs<LeadsParams>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    let params = new URLSearchParams({ ...(filters as object) });
    params.append("limit", String(limit));
    params.append("page", String(page));
    let url = "users";

    if (statusText) {
      statusText
        .filter((s) => s !== "TOTAL" && s !== "total")
        .map((s) => {
          params.append("statusText", s.toUpperCase());
        });
    }

    if (to && from) {
      params.append("to", format(to, "yyyy-MM-dd"));
      params.append("from", format(from, "yyyy-MM-dd"));
    }

    if (searchType && searchQuery) {
      params.append(searchType, searchQuery);
      url = "searchOnlyUsers";
    }
    if (productType) {
      params.append("loanType", productType);
    }
    if (constitution) {
      params.append("constitution", constitution);
    }
    if (sourceEntityIDs?.length > 0) {
      sourceEntityIDs?.map((entityID) =>
        params.append("sourceEntityIDs", entityID)
      );
    }
    if (dsaIDs?.length > 0) {
      dsaIDs?.map((dsaID) => params.append("dsaID", dsaID));
    }
    params.append("filter", dateFilter);

    return await Get<LeadResponse>(
      `/masterDashboard/${url}?${params.toString()}`,
      token || "",
      headers
    );
  }

  static async getFilterCounts({
    page = 1,
    searchType = "",
    searchQuery = "",
    filters = {},
    statusText = [],
    token = "",
    limit = 10,
    dateFilter,
    productType = "",
    sourceEntityIDs = [],
    to = null,
    from = null,
    constitution = "",
    pageName = "",
    dsaIDs = [],
  }: ServiceMethodArgs<LeadsParams>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    let params = new URLSearchParams({ ...(filters as object) });
    params.append("limit", String(limit));
    params.append("page", String(page));
    if (statusText) {
      statusText
        .filter((s) => s !== "TOTAL" && s !== "total")
        .map((s) => {
          params.append("statusText", s);
        });
    }
    if (searchType && searchQuery) {
      params.append(searchType, searchQuery);
    }
    if (to && from) {
      params.append("to", format(to, "yyyy-MM-dd"));
      params.append("from", format(from, "yyyy-MM-dd"));
    }
    if (productType) {
      params.append("loanType", productType);
    }
    if (sourceEntityIDs?.length > 0) {
      sourceEntityIDs?.map((entityID) =>
        params.append("sourceEntityIDs", entityID)
      );
    }
    if (constitution) {
      params.append("constitution", constitution);
    }
    if (dsaIDs?.length > 0) {
      dsaIDs?.map((dsaID) => params.append("dsaID", dsaID));
    }

    params.append("filter", dateFilter);

    let URL = `/masterDashboard/usersAggregateCount?${params.toString()}`;
    return await Get<any>(URL, token, headers);
  }

  // setup interface for all API return types
  static async getUserActivity({
    userID,
    page = 1,
    token,
    limit = 8,
    programID,
    pageName,
  }: ServiceMethodArgs<{
    userID: string;
    page?: number;
    limit?: number;
    programID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();
    searchParams.set("userID", userID);
    searchParams.set("page", String(page));
    searchParams.set("limit", String(limit));
    if (programID) {
      searchParams.set("programID", programID);
    }

    return await Get(
      `/masterDashboard/userActivity?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async getUserProfile({
    userID,
    programID,
    token,
    pageName,
  }: ServiceMethodArgs<{ userID: string; programID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const searchParams = new URLSearchParams();
    searchParams.append("userID", userID);
    if (programID) {
      searchParams.append("programID", programID);
    }

    return await Get(
      `/masterDashboard/userProfile?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async skipBankConnect({
    userID,
    token,
    pageName,
  }: ServiceMethodArgs<{ userID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get<any>(
      `/masterDashboard/skipBankConnect?userID=${userID}`,
      token,
      headers
    );
  }

  static async disqualifyRevert({
    token,
    payload,
    pageName,
  }: ServiceMethodArgs<{
    payload: {
      userID: string;
      action: string;
      offerDetails?: {
        amount: number;
        interest: number;
        processingFee: number;
        processingFeeType: string;
        tenure: number;
        maxEMI: number;
        reason: string;
      };
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/disqualifyRevert`,
      token,
      payload,
      headers
    );
  }

  static async getBusinessDetails({
    userID,
    token,
    pageName,
  }: ServiceMethodArgs<{ userID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get<any>(
      `/masterDashboard/getBusinessDetails?userID=${userID}`,
      token,
      headers
    );
  }

  static async getBankStatements({
    userID,
    token,
    pageName,
  }: ServiceMethodArgs<{ userID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/getBankStatements?userID=${userID}`,
      token,
      headers
    );
  }

  static async refreshBankStatement({
    userID,
    token,
    pageName,
  }: ServiceMethodArgs<{ userID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/refreshStatements?userID=${userID}`,
      token,
      headers
    );
  }

  static async expireUser({
    token,
    payload,
    pageName,
  }: ServiceMethodArgs<{ payload: { userID: string; reason: string } }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(`/masterDashboard/expireUser`, token, payload, headers);
  }
}
