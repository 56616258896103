import { defer } from "react-router";

import { GetWorkflowResponse } from "../WorkflowTimeline/interface";
import { LoanApplicationService } from "../../services/LoanApplication";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export default async function ({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID, sourceEntityID = "" } = params;

  return defer({
    response: await LoanApplicationService.getWorkflows({
      pageName,
      token: getToken(),
      loanApplicationID: loanID as string,
    }),
  });
}
