import Button from "../../atomic/Button";
import { notify } from "../../../shared/context/AlertContext";
import GetAuthContext from "../../../shared/context/AuthContext";
import GetMixpanelContext from "../../../shared/context/MixpanelContext";
import { LoanApplicationService } from "../../../services/LoanApplication";
import GetLeadsContext from "../../../pages/LeadDetails/states/LeadsContext";

interface IProps {
  setOpen: Function;
  reload: Function;
  userID: string;
}
export default function InitiateNewApplicationModal({
  userID,
  setOpen,
  reload,
}: IProps) {
  const { fetchToken } = GetAuthContext();
  const { trackUserAction } = GetMixpanelContext();
  const { pageName } = GetLeadsContext();

  async function onSubmit() {
    const response = await LoanApplicationService.applyNewLoan({
      userID,
      token: fetchToken(),
      pageName,
    });

    if (response.status) {
      trackUserAction("initiate_new_application", {
        userID,
      });
      reload();
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
    setOpen(false);
  }

  return (
    <form>
      <fieldset className="flex items-center justify-end">
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="text-sm mr-4 px-4 py-2 w-auto rounded-lg bg-white border border-gray-300 text-gray-700 flex items-center justify-center font-medium"
        >
          No
        </button>
        <Button
          type="button"
          color="primary"
          onClick={() => onSubmit()}
          className="text-sm px-4 py-2 w-auto rounded-lg text-white  flex items-center justify-center font-medium"
        >
          Yes, Initiate New Application
        </Button>
      </fieldset>
    </form>
  );
}
