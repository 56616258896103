import classNames from "classnames";

export const capitalize = (str: string) => {
  const splitStr = str?.toLowerCase().split(" ");
  return splitStr
    ?.map((st: string) => st.charAt(0).toUpperCase() + st.slice(1))
    ?.join(" ");
};

export const userActiveStruct = ({
  name,
  active,
}: {
  name: string;
  active: boolean;
}) => {
  return (
    <div className="flex items-center text-xs">
      <span
        className={classNames(
          "h-2 w-2 rounded-full mr-1.5",
          active ? "bg-green-600" : "bg-transparent"
        )}
      />
      {capitalize(name || "")}
    </div>
  );
};
