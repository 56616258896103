import { FinBoxResponse } from "./APIResponse";
import { AuthService } from "../../services/apis/authService";
import { FINBOX_USER, FINBOX_USER_PERMISSIONS } from "../context/AuthContext";
import LZString from "lz-string";

export const getTokenFromStorage = () => {
  let value = "";
  try {
    value = localStorage.getItem(AuthService.finboxToken) || "";
  } catch (error) {}
  return value;
};

export const getPermissionsFromStorage = () => {
  let value = "";
  try {
    value =
      LZString.decompress(
        localStorage.getItem(FINBOX_USER_PERMISSIONS) ?? ""
      ) || "";
    if (value) {
      value = JSON.parse(value);
      value = value.permissions;
    }
  } catch (error) {
    console.error(error);
  }
  return value;
};

export const getUserInfoFromLocalStorage = () => {
  return JSON.parse(
    LZString.decompress(localStorage.getItem("finbox-dashboard-user") ?? "") ||
      "{}"
  );
};

const forbiddenRoutes = [
  "signin",
  "reset-password",
  "verify-account-details",
  "/verify-mobile-password",
  "/verify-otp",
  "/authenticate",
  "/password-expire",
  "/signin-mobile",
];

function handleInvalidTokenError() {
  console.log("handle invalid token error");
  localStorage.removeItem(FINBOX_USER_PERMISSIONS);
  localStorage.removeItem(FINBOX_USER);
  localStorage.removeItem(AuthService.finboxToken);
  // abortController.abort();

  const currentRoute = window.location.href;
  const shouldRedirectToSignIn = !forbiddenRoutes.some(
    (route) => currentRoute.indexOf(route) !== -1
  );
  if (shouldRedirectToSignIn) {
    window.location.href = "/signin";
  }
}

export const Post = async <T extends any>(
  path: string,
  token: string,
  data: Record<string, any>,
  headers?: Record<string, any>,
  multipart?: boolean,
  requestOptionsPayload?: any
): Promise<FinBoxResponse<T> & { statusCode: number }> => {
  var myHeaders = new Headers();
  let shouldStringify = false;

  if (!multipart) {
    myHeaders.append("Content-Type", "application/json");
    shouldStringify = true;
  }
  headers?.forEach((header, key) => {
    myHeaders.append(key, header);

    const isApplicationJSON =
      key.toLowerCase() === "content-type" &&
      headers.get(key).toLowerCase() === "application/json";
    if (isApplicationJSON) {
      shouldStringify = true;
    }
  });

  if (token) myHeaders.append("token", token);

  var requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: shouldStringify ? JSON.stringify(data) : data,
    redirect: "follow",
    // credentials :"include",
    ...(requestOptionsPayload || {}),
  };

  let json: FinBoxResponse<T> & { statusCode: number };
  try {
    const response = await fetch(
      `${import.meta.env.REACT_APP_API_ENDPOINT}${path}`,
      requestOptions
    );
    json = await response.json();
    json["statusCode"] = response.status;
    if (response.status == 401) {
      handleInvalidTokenError();
    }
  } catch (error) {
    json = {
      data: null,
      status: false,
      error: "something went wrong",
      statusCode: 500,
    };
  }
  return json;
};

export const Put = async <T extends any>(
  path: string,
  token: string,
  data: Record<string, any>,
  headers?: Record<string, any>,
  multipart?: boolean
): Promise<FinBoxResponse<T> & { statusCode: number }> => {
  var myHeaders = new Headers();
  let shouldStringify = false;

  if (!multipart) {
    myHeaders.append("Content-Type", "application/json");
    shouldStringify = true;
  }
  headers?.forEach((header, key) => {
    myHeaders.append(key, header);

    const isApplicationJSON =
      key.toLowerCase() === "content-type" &&
      headers.get(key).toLowerCase() === "application/json";
    if (isApplicationJSON) {
      shouldStringify = true;
    }
  });

  if (token) myHeaders.append("token", token);

  var requestOptions: any = {
    method: "PUT",
    headers: myHeaders,
    body: shouldStringify ? JSON.stringify(data) : data,
    redirect: "follow",
  };

  let json: FinBoxResponse<T> & { statusCode: number };
  try {
    const response = await fetch(
      `${import.meta.env.REACT_APP_API_ENDPOINT}${path}`,
      requestOptions
    );
    json = await response.json();
    json["statusCode"] = response.status;
    if (response.status == 401) {
      handleInvalidTokenError();
    }
  } catch (error) {
    json = {
      data: null,
      status: false,
      error: "something went wrong",
      statusCode: 500,
    };
  }
  return json;
};

export const Get = async <T extends any>(
  path: string,
  token: string,
  headers?: Record<string, any>
): Promise<FinBoxResponse<T> & { statusCode: number }> => {
  let myHeaders = new Headers();
  if (!headers?.has("Content-Type")) {
    myHeaders.append("Content-Type", "application/json");
  }
  if (token) myHeaders.append("token", token);
  if (headers)
    headers.forEach((header: any, key: any) => myHeaders.append(key, header));

  var requestOptions: RequestInit = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  let json: FinBoxResponse<T> & { statusCode: number };
  try {
    const response = await fetch(
      `${import.meta.env.REACT_APP_API_ENDPOINT}${path}`,
      requestOptions
    );
    if (myHeaders.get("Content-Type") !== "text/html") {
      json = await response.json();
      json["statusCode"] = response.status;
    } else {
      json = {
        data: await response.text(),
        status: true,
      };
    }
    if (response.status == 401) {
      handleInvalidTokenError();
    }
  } catch (error) {
    json = {
      data: null,
      status: false,
      error: "something went wrong",
      statusCode: 500,
    };
  }
  return json;
};

export const get = async <T extends any>(
  path: string,
  pageName: string
): Promise<FinBoxResponse<T> & { statusCode: number }> => {
  const token = getTokenFromStorage();
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  if (token) myHeaders.append("token", token);
  if (pageName) myHeaders.append("page-header", pageName);

  var requestOptions: RequestInit = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  let json: FinBoxResponse<T> & { statusCode: number };
  try {
    const response = await fetch(
      `${import.meta.env.REACT_APP_API_ENDPOINT}${path}`,
      requestOptions
    );
    json = await response.json();
    json["statusCode"] = response.status;
    if (response.status == 401 || json.statusCode == 401) {
      handleInvalidTokenError();
    }
  } catch (error) {
    json = {
      data: null,
      status: false,
      error: "something went wrong",
      statusCode: 500,
    };
  }
  return json;
};
export const Delete = async <T extends any>(
  path: string,
  token: string,
  body?: any,
  headers?: Record<string, any>
): Promise<FinBoxResponse<T> & { statusCode: number }> => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  if (token) myHeaders.append("token", token);

  if (headers)
    headers.forEach((header: any, key: any) => myHeaders.append(key, header));

  var requestOptions: RequestInit = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };
  if (typeof body !== "undefined" && Object?.keys(body)?.length) {
    requestOptions["body"] = JSON.stringify(body);
  }
  let json: FinBoxResponse<T> & { statusCode: number };
  try {
    const response = await fetch(
      `${import.meta.env.REACT_APP_API_ENDPOINT}${path}`,
      requestOptions
    );
    json = await response.json();
    json["statusCode"] = response.status;
    if (response.status == 401) {
      handleInvalidTokenError();
    }
  } catch (error) {
    json = {
      data: null,
      status: false,
      error: "something went wrong",
      statusCode: 500,
    };
  }
  return json;
};

export function composedLoader(loader: any, pageName?: string) {
  const getToken = () => (window as any).token || getTokenFromStorage();
  return (args: any) => {
    const getPermissions = () =>
      (window as any).permissions || getPermissionsFromStorage();
    if (!pageName) {
      throw "No page name set";
    }
    const permissions = getPermissions();
    if (permissions[pageName!]) {
      return loader({ ...args, getToken, pageName, getPermissions });
    } else {
      return function () {
        return {};
      };
    }
  };
}

export const Patch = async <T extends any>(
  path: string,
  token: string,
  data: Record<string, any>,
  headers?: Record<string, any>,
  multipart?: boolean
): Promise<FinBoxResponse<T> & { statusCode: number }> => {
  var myHeaders = new Headers();
  let shouldStringify = false;
  if (!multipart) {
    myHeaders.append("Content-Type", "application/json");
    shouldStringify = true;
  }
  headers?.forEach((header, key) => {
    myHeaders.append(key, header);

    const isApplicationJSON =
      key.toLowerCase() === "content-type" &&
      headers.get(key).toLowerCase() === "application/json";
    if (isApplicationJSON) {
      shouldStringify = true;
    }
  });

  if (token) myHeaders.append("token", token);

  var requestOptions: any = {
    method: "PATCH",
    headers: myHeaders,
    body: shouldStringify ? JSON.stringify(data) : data,
    redirect: "follow",
  };

  let json: FinBoxResponse<T> & { statusCode: number };
  try {
    const response = await fetch(
      `${import.meta.env.REACT_APP_API_ENDPOINT}${path}`,
      requestOptions
    );
    json = await response.json();
    json["statusCode"] = response.status;

    if (response.status == 401 || json.statusCode == 401) {
      handleInvalidTokenError();
    }
  } catch (error) {
    json = {
      data: null,
      status: false,
      error: "something went wrong",
      statusCode: 500,
    };
  }
  return json;
};
