import React, { ChangeEvent, HTMLInputTypeAttribute } from "react";
import styled from "styled-components";
import { MdEdit } from "react-icons/md";
import { RiErrorWarningLine } from "react-icons/ri";

export const statusColors = {
  LOAN_APPROVED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
  },
  CANCELLED: {
    color: "#eb5757",
    bg: "#fdebeb",
  },
  CLOSED: {
    color: "#3c3c3c",
    bg: "#dedede",
  },
  LOAN_REJECTED: {
    color: "#eb5757",
    bg: "#fdebeb",
  },
  BANK_FAILED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
  },
  BANK_PROCESSING: {
    color: "#24ca7a",
    bg: "#e5f9ef",
  },
  BANK_ADDED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
  },
  SIGN_AGREEMENT: {
    color: "#24ca7a",
    bg: "#e5f9ef",
  },
  DISBURSED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
  },
  USER_QUALIFIED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
  },
  USER_DISQUALIFIED: {
    color: "#eb5757",
    bg: "#fdebeb",
  },
  COMPLETED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
  },
  PROCESSING_ERROR: {
    color: "#eb5757",
    bg: "#fdebeb",
  },
  ACTION_REQUIRED: {
    color: "#ffb700",
    bg: "#ffb70020",
  },
  PASS: {
    color: "#24ca7a",
    bg: "#e5f9ef",
  },
  FAIL: {
    color: "#eb5757",
    bg: "#fdebeb",
  },
};
export const Chip = styled.div<{
  status?: keyof typeof statusColors;
}>`
  background: ${(props) =>
    props.status && statusColors[props.status]
      ? statusColors[props.status].bg
      : "#e2f4fd"};
  text-transform: capitalize;
  color: ${(props) =>
    props.status && statusColors[props.status]
      ? statusColors[props.status].color
      : "#11a1f1"};
  white-space: wrap;
  min-width: 115px;
  width: fit-content;
  text-align: center;
  height: fit-content;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
`;

export const initializeName = (str: string) => {
  return str
    .toLowerCase()
    .replace("mr. ", "")
    .replace("ms. ", "")
    .replace("mrs. ", "")
    .replace("shri ", "")
    .split(" ")
    .slice(0, 2)
    .map((n) => n[0])
    .join("")
    .toUpperCase();
};
export const ErrorLabel = styled.div`
  /* background: red; */
  color: #ff5757;
  font-size: 9px;
  font-weight: bold;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  z-index: 2;
`;

export const ButtonCore = styled.div<{
  isSmall?: boolean;
  disabled?: boolean;
  color?: string;
}>`
  width: max-content;
  height: max-content;
  background: ${(props) =>
    props.color == "white"
      ? "#e3e3e3"
      : props.color == "red"
      ? "#FF5757"
      : props.color == "blue"
      ? "#318fff"
      : "#318fff"};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
  font-size: ${(props) => (props.isSmall ? "12px" : "16px")};
  color: ${(props) => (props.color == "white" ? "#777777" : "white")};
  padding: 7px 20px;
  border: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  outline: none;
  margin-right: ${(props) => (props.color == "white" ? "20px" : 0)};

  :hover,
  :visited,
  :active,
  :link {
    color: ${(props) => (props.color == "white" ? "#777777" : "white")};
    text-decoration: none;
    background-color: ${(props) =>
      props.color == "white"
        ? "#c5c5c5"
        : props.color == "red"
        ? "#D94545"
        : props.color == "blue"
        ? "#4A98D7"
        : "#318fffb0"};
  }
`;

export const TopBar = styled.div`
  padding-top: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
  @media (max-width: 786px) {
    flex-wrap: wrap;
  }
`;
export const Bold24 = styled.span`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 15px;
`;

export const Container = styled.div`
  border-radius: 4px;
  background-color: #f2f5f7;
  /* background-color: #f9f9f9; */
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 50px;
  height: 100%;
  @media (max-width: 768px) {
    padding: 0 4%;
  }
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #bdbdbd;
    border: 4px solid #f4f4f4;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const Bold14 = styled.span`
  font-weight: 600;
  color: #3e3e3e;
  font-size: 14px;
`;
export const Card = styled.div`
  background: white;
  border-radius: 4px;
  padding: 15px 25px;
  margin-bottom: 20px;
  overflow-y: hidden;
  height: 100%;

  h4 {
    margin-bottom: 20px;
  }

  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }

  /* scrollbar itself */

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #bdbdbd;
    border: 4px solid #f4f4f4;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;
export const BlueLink = styled.div`
  background: none;
  font-weight: 400;
  align-items: center;
  font-size: 13px;
  border: none;
  cursor: pointer;
  color: #11a1f1;

  :hover {
    color: #11a1f1;
    text-decoration: underline;
  }

  :link {
    color: #11a1f1;
  }

  :visited {
    color: #11a1f1;
  }

  :active {
    color: #11a1f1;
  }
`;

export const Search = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  order: 0;
  max-width: 550px;
  @media (max-width: 786px) {
    order: 1;
    width: -webkit-fill-available;
    margin: 15px -16px 15px 0px;
  }
`;
export const ButtonSearch = styled.div`
  background-color: #11a1f1;
  color: white;
  border-radius: 0 2px 2px 0;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #58bdf5;
  }

  img {
    height: 20.8px;
  }
`;

export const DropdownList = styled.div`
  ul {
    right: 0;
    padding: 0px;
    top: 10;
    background: white;
    border: 1px solid #ececec;
  }

  li {
    margin: 0;
    padding: 0.55rem 0.7rem;
    font-size: 14px;
    width: 100%;
    border-top: 1px solid #fafafa;

    &:hover {
      /* background: #f3f3f3; */
      /* color: #888888; */
      cursor: pointer;
      background: #f6f6f6;
    }
  }

  .menu {
    background: white;
    box-shadow: 0px 0px 10px 3px #00000010;
    overflow: auto;
    animation: slideDown 0.6s ease-in 0s 1 normal forwards;
    max-height: 300px;
  }
`;

interface LabelWithInputProps {
  label?: string;
  type?: HTMLInputTypeAttribute;
  placeholder: string;
  value: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  readOnly?: boolean;
  disabled?: boolean;
  search?: boolean;
  style?: React.CSSProperties;
  error?: boolean;
  errorText?: string;
  autoComplete?: string;
  maxLength?: number;
}

export const LabelWithInput = (props: LabelWithInputProps) => {
  return (
    <div style={{ position: "relative" }}>
      {props.label && (
        <div style={{ marginTop: "5px" }}>
          <Bold14>{props.label}</Bold14>
        </div>
      )}
      <InputContainer
        type={props.type ? props.type : "text"}
        placeholder={props.placeholder ? props.placeholder : ""}
        value={props.value}
        onChange={(e) => props.onChange && props.onChange(e)}
        name={props.name}
        readOnly={props.readOnly}
        disabled={props.disabled}
        autoComplete={props.autoComplete}
        style={{
          margin: props.search ? 0 : undefined,
          borderRight: props.search ? "none" : undefined,
          border: props.search ? "2px 0 0 2px" : undefined,
          height: props.search ? "34.8px" : undefined,
          ...props.style,
        }}
        maxLength={props.maxLength}
      />
      {props.error ? <ErrorTip>{props.errorText}</ErrorTip> : ""}
    </div>
  );
};
export const InputContainer = styled.input`
  margin-top: 8px;
  padding: 8px 12px;
  background: #ffffff;
  border: 1px solid #dee2e9;
  box-sizing: border-box;
  border-radius: 2px;

  :focus {
    outline: 1px solid #091d2c;
  }
`;

export const SidebarContainer = styled.div`
  /* min-width: 350px; */
  width: 430px;
  height: 100%;
  margin-right: 20px;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  /*  */
`;
export const SideCard = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #ececec;
  border-radius: 4px;
  background: white;
  padding: 18px;
  transition: 0.2s all ease-in;
  margin-bottom: 16px;

  .name {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .appNum {
    font-size: 16px;
    font-weight: 500;
    color: #777777;
    margin: 0;
  }

  &:first-child {
    text-align: center;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
`;
export const humanize = (str: string) => {
  return (
    str
      ?.split("_")
      ?.map((w) => w?.[0]?.toUpperCase() + w?.slice(1))
      ?.join(" ") ?? ""
  );
};
export const dehumanize = (str: string) => {
  return str
    ?.split(" ")
    ?.map((w) => w[0].toLowerCase() + w.slice(1))
    ?.join("_");
};

export const Key = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #777777;
  text-transform: uppercase;
`;

export const Value = styled.div`
  font-size: 14px;
  color: #3e3e3e;
  width: fit-content;
  max-width: 100%;
  font-weight: 500;
  overflow-wrap: break-word;
  text-align: initial;
`;

export const Pair = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content:space-between; */
  align-items: baseline;
  margin: 10px 0;
`;

export const InformationBar = styled.div`
  display: flex;
  padding: 0 9% 15px;
  background: white;
`;

export const ActionSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SecondaryButton = styled.div`
  border: 1px solid #ececec;
  border-radius: 2px;
  padding: 2px 6px;
  cursor: pointer;
  margin-left: 8px;

  &::first-child {
    margin-right: 0;
  }
`;

export const OutlineButton = styled.div`
  width: 100px;
  height: max-content;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #318fff;
  border-radius: 4px;
  cursor: pointer;
  color: #318fff;
  font-weight: 500;
  padding: 7px 20px;
`;

export const DropdownDownload = styled.div`
  ul {
    width: 210px;
    right: 0;
    padding: 0px;
    top: 10;
    background: white;
    border: 1px solid #ececec;
  }

  li {
    font-size: 14;
    margin: 0;
    padding: 5px 15px;
    width: 100%;
    border-bottom: 1px solid #ececec;

    &:hover {
      background: #f3f3f3;
      cursor: pointer;
    }

    &:first-child {
      padding-top: 10px;
    }

    &:last-child {
      padding-bottom: 10px;
      border-bottom: none;
    }
  }
`;

export const InformationCard = styled.div`
  display: flex;
  border: 1px solid #ececec;
  border-radius: 2px;
  padding: 20px;
  margin-right: 30px;
  min-width: 450px;

  h1 {
    font-size: 18px;
    color: #3e3e3e;
    font-weight: 500;
  }

  img {
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    height: 120px;
    width: 120px;
    margin-right: 30px;
  }

  .key {
    font-size: 12px;
    color: #777777;
    font-weight: 500;
  }

  .value {
    font-size: 12px;
    color: #3e3e3e;
    font-weight: 500;
    margin-left: 4px;
  }

  .infoRow {
    display: flex;
    margin-top: 12px;
  }
`;

const Chipped = styled(Chip)<{
  active?: boolean;
}>`
  color: ${(props) => (props.active ? "white" : "black")};
  background: ${(props) => (props.active ? "#8CAFCE" : "#e9ecef")};
  font-weight: ${(props) => (props.active ? 600 : 400)};
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  letter-spacing: 1px;
  margin-right: 10px;
  cursor: pointer;
  @media (max-width: 398px) {
    font-size: 10px;
  }
`;

interface ChipRadioProps<T extends string | number> {
  label: string;
  options: T[];
  selected: T;
  onChange: (e: T) => void;
  error?: boolean;
  errorText?: string;
}

export const ChipRadio = <T extends string | number>(
  props: ChipRadioProps<T>
) => {
  return (
    <div style={{ marginTop: "15px", position: "relative" }}>
      <Bold14>{props.label}</Bold14>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          marginTop: "8px",
        }}
      >
        {props.options.map((item, index) => (
          <Chipped
            key={index}
            active={item === props.selected}
            onClick={() => {
              props.onChange(item);
            }}
          >
            {item}
          </Chipped>
        ))}
      </div>
      {props.error ? <ErrorTip>{props.errorText}</ErrorTip> : ""}
    </div>
  );
};

export const FormSectionHead = styled.div`
  font-weight: 700;
  color: #737373;
  font-size: 18px;
  margin-top: 55px;
  margin-bottom: -15px;
`;

export const ErrorTip = styled.div`
  color: red;
  font-size: 11px;
  position: absolute;
  bottom: -16px;
  left: 0;
`;

export const EditButton = styled(MdEdit)`
  font-size: 13px;
  margin-bottom: -1px;
  margin-left: 3px;

  &:hover {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  width: 100%;
  border-top: 1px solid #ececec;
  box-sizing: border-box;
  padding-top: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const BoxCard = styled.div`
  background: white;
  border-radius: 5px;
  padding: 20px 17px;
  box-sizing: border-box;
  text-align: left;
  box-shadow: 0px 0px 13px -6px rgba(195, 207, 222, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FlexRow = styled("div")<{
  alignItems?: string;
  justifyContent?: string;
}>`
  display: flex;
  align-items: ${(props) => props.alignItems || "center"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
`;

interface NoteProps {
  text: string;
  color: string;
}

export const Note = (props: NoteProps) => {
  return (
    <div
      style={{
        display: "flex",
        padding: "0.5rem",
        justifyContent: "flex-start",
        margin: "0.5rem 0",
        alignItems: "center",
        borderRadius: "7px",
        border: "1px solid " + props.color + "33",
        background: props.color + "0a",
      }}
    >
      <RiErrorWarningLine
        fill={props.color}
        style={{ marginRight: "0.5rem" }}
      />
      <span style={{ color: props.color, fontSize: "0.9em" }}>
        {props.text}
      </span>
    </div>
  );
};

export const RequiredAsterisk = styled.span`
  font-size: 12px;
  color: red;
  margin-right: 2px;

  &::before {
    content: "*";
  }
`;

export const InfoText = styled.p`
  color: #666;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px !important;
`;

export const STATUS_CONFIG = {
  USER_CREATED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "DashboardUser Created",
  },
  USER_TOKEN_ISSUED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "DashboardUser Token Issued",
  },
  USER_PROFILE_UPDATED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "DashboardUser Profile Updated",
  },
  USER_QUALIFIED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "DashboardUser Qualified",
  },
  USER_DISQUALIFIED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "DashboardUser Disqualified",
  },
};

export const LOAN_STATUS_CONFIG = {
  FRESH_LOAN: {
    bg: "rgb(226, 244, 253)",
    color: "rgb(17, 161, 241)",
    label: "Fresh Loan",
  },
  LOAN_APPROVED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "Loan Approved",
  },
  CANCELLED: {
    color: "#eb5757",
    bg: "#fdebeb",
    label: "Cancelled",
  },
  CLOSED: {
    color: "#3c3c3c",
    bg: "#dedede",
    label: "Closed",
  },
  LOAN_DETAILS_SUBMITTED: {
    bg: "rgb(226, 244, 253)",
    color: "rgb(17, 161, 241)",
    label: "Details Submitted",
  },
  KYC_SUCCESS: {
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "KYC Success",
  },
  KYC_PROCESSING: {
    bg: "rgb(226, 244, 253)",
    color: "rgb(17, 161, 241)",
    label: "KYC Processing",
  },
  KYC_REJECTED: {
    color: "#eb5757",
    bg: "#fdebeb",
    label: "KYC Rejected",
  },
  LOAN_REJECTED: {
    color: "#eb5757",
    bg: "#fdebeb",
    label: "Loan Rejected",
  },
  BANK_FAILED: {
    color: "#eb5757",
    bg: "#fdebeb",
    label: "Bank Failed",
  },
  BANK_PROCESSING: {
    bg: "rgb(226, 244, 253)",
    color: "rgb(17, 161, 241)",
    label: "Bank Processing",
  },
  BANK_ADDED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "Bank Added",
  },
  SIGN_AGREEMENT: {
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "Sign Agreement",
  },
  DISBURSED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "Disbursed",
  },
  USER_QUALIFIED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "DashboardUser Qualified",
  },
  USER_DISQUALIFIED: {
    color: "#eb5757",
    bg: "#fdebeb",
    label: "DashboardUser Disqualified",
  },
  COMPLETED: {
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "Completed",
  },
  PROCESSING_ERROR: {
    color: "#eb5757",
    bg: "#fdebeb",
    label: "Processing Error",
  },
  ACTION_REQUIRED: {
    color: "#ffb700",
    bg: "#ffb70020",
    label: "Action Required",
  },
  PASS: {
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "Pass",
  },
  FAIL: {
    color: "#eb5757",
    bg: "#fdebeb",
    label: "Fail",
  },
  UNDER_REVIEW: {
    bg: "#FBF0B2",
    color: "#E7B10A ",
    label: "Under Review",
  },
};
export const Fieldset = styled.fieldset`
  position: relative;

  p {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 600;
  }

  input {
    margin-top: 8px;
    padding: 8px 12px;
    background: #ffffff;
    border: 1px solid #dee2e9;
    box-sizing: border-box;
    border-radius: 2px;
  }
`;
