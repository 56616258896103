import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { memo, useMemo, useState } from "react";

import Button from "../atomic/Button";
import Input from "./FormElements/Input";
import DropdownWithSearch from "../atomic/DropdownWithSearch";
import GetAuthContext from "../../shared/context/AuthContext";
import GetLeadsContext from "../../pages/LeadDetails/states/LeadsContext";

type SubmitForm = {
  reason: string;
  amount: string;
  maxEMI: string;
  tenure: string;
  interest: string;
  processingFeeFlat?: string;
  processingFeePerc?: string;
  processingFeeComponent: string | number;
};

type OfferDetails = {
  amount: number;
  tenure: number;
  maxEMI: number;
  reason: string;
  interest: number;
  processingFee: number;
  processingFeeType: string;
};

type Payload = {
  token: string;
  payload: {
    userID: string;
    action: string;
    offerDetails: OfferDetails;
  };
};

type Props = {
  isOpen: (args: boolean) => void;
  confirmPopUp: (args: Payload) => void;
};

function ManualOfferModal({ isOpen, confirmPopUp }: Props) {
  const {
    register,
    setError,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { userID = "" } = useParams();
  const { fetchToken } = GetAuthContext();
  const [processingFee, setProcessingFee] = useState<number>(0);
  const [processingFeeType, setProcessingFeeType] = useState<string>("flat");
  const { pageName } = GetLeadsContext();

  const warningPopupHandler = (data: SubmitForm) => {
    if (!processingFeeType) {
      setError("processingFeeType", {
        type: "validate",
        message: "Required*",
      });
      return;
    }
    isOpen(false);
    let feeType;
    if (processingFeeType === "perc+flat") {
      if (Number(data?.processingFeeComponent) > 0) {
        feeType = `perc+${data?.processingFeeComponent}`;
      } else {
        feeType = "perc";
      }
    }
    const payload = {
      token: fetchToken(),
      payload: {
        userID: userID || "",
        action: "REVERT_AND_OFFER",
        offerDetails: {
          amount: Number(data?.amount),
          interest: Number(data?.interest),
          tenure: Number(data?.tenure),
          maxEMI: Number(data?.maxEMI),
          reason: data?.reason,
          // processingFee: processingFee,
          processingFee: getProcessingFeeValue(data),
          processingFeeType: feeType
            ? feeType?.toUpperCase()
            : processingFeeType?.toUpperCase(),
        },
      },
      pageName,
    };
    confirmPopUp(payload);
  };

  const getProcessingFeeValue = (data: SubmitForm) => {
    if (processingFeeType === "perc") {
      return Number(data?.processingFeePerc);
    } else if (processingFeeType === "perc+flat") {
      return Number(data?.processingFeePerc);
    }
    return Number(data?.processingFeeFlat);
  };

  const processingFeeTypeMap = useMemo(() => {
    return {
      flat: {
        name: "FLAT",
        value: "flat",
      },
      perc: {
        name: "PERC",
        value: "perc",
      },
      "perc+flat": {
        name: "PERC+FLAT",
        value: "perc+flat",
      },
    };
  }, []);

  const getFormattedCurrency = (number: number) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    }).format(number);
  };

  const processingFeeOptions = useMemo(
    () => [
      {
        name: "FLAT",
        value: "flat",
      },
      {
        name: "PERC",
        value: "perc",
      },
      {
        name: "PERC+FLAT",
        value: "perc+flat",
      },
    ],
    []
  );

  function calculateProcessingFee({
    currentProcessingFeeType = processingFeeType,
    currentProcessingFeeFlat = getValues("processingFeeFlat"),
    currentProcessingFeePerc = getValues("processingFeePerc"),
    currentProcessingFeeComponent = getValues("processingFeeComponent"),
    currentAmount = getValues("amount"),
  }) {
    switch (currentProcessingFeeType) {
      case "flat":
        setProcessingFee(Number(currentProcessingFeeFlat));
        break;

      case "perc":
        setProcessingFee(
          (Number(currentProcessingFeePerc) * Number(currentAmount)) / 100
        );
        break;

      case "perc+flat":
        setProcessingFee(
          Number(currentProcessingFeeComponent) +
            (currentProcessingFeePerc * Number(currentAmount)) / 100
        );
        break;

      default:
        break;
    }
  }

  const processingFeeErrorMsg = (type: string, max: string): string => {
    const errorMessage: { [key: string]: string } = {
      required: "required",
      validateNumber: "Enter a valid number",
      validateProcessingFee: "Enter a valid number",
      min: "The processing fee must be greater than or equal to 0.",
      validateProcessingFeeFlat: "Enter a valid flat processing fee",
      max: `The processing fee must be less than or equal to ${max}.`,
      validateProcessingFeePerc: "Enter a valid processing fee percent",
    };

    return errorMessage?.[type] ?? "";
  };

  return (
    <div>
      <form onSubmit={handleSubmit(warningPopupHandler)}>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Input
              type="text"
              name="amount"
              label="Amount"
              placeholder={"Enter amount"}
              styles={{
                label: "text-xs",
                input: "text-xs placeholder:text-xs",
                container: "w-full",
              }}
              register={register("amount", {
                required: "required",
                validate: {
                  validateAmount: (v) => !isNaN(v) && Number(v) > 0,
                },
              })}
              error={errors.amount}
              errorText={
                errors.amount?.type === "required"
                  ? "required"
                  : errors.amount?.type === "validateAmount"
                  ? "Enter a valid number"
                  : ""
              }
            />
          </div>
          <div>
            <Input
              type="text"
              name="tenure"
              label="Tenure (months)"
              styles={{
                container: "w-full",
                input: "text-xs placeholder:text-xs",
                label: "text-xs",
              }}
              placeholder={"Enter tenure"}
              register={register("tenure", {
                required: "required",
                validate: {
                  validateTenure: (v) =>
                    !isNaN(v) && Number(v) > 0 && Number(v) <= 120,
                },
              })}
              error={errors.tenure}
              errorText={
                errors.tenure?.type === "required"
                  ? "required"
                  : errors.tenure?.type === "validateTenure"
                  ? "Enter a valid number"
                  : ""
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <Input
              type="text"
              name="interest"
              label="Interest per annum (%)"
              styles={{
                label: "text-xs",
                container: "w-full",
                input: "text-xs placeholder:text-xs",
              }}
              placeholder={"Enter interest"}
              register={register("interest", {
                required: "required",
                validate: {
                  validateInterest: (v) =>
                    !isNaN(v) && Number(v) >= 0 && Number(v) <= 100,
                },
              })}
              error={errors.interest}
              errorText={
                errors.interest?.type === "required"
                  ? "required"
                  : errors.interest?.type === "validateInterest"
                  ? "Enter a valid number"
                  : ""
              }
            />
          </div>
          <div>
            <DropdownWithSearch
              hasSearch={false}
              label="Processing Fee Type"
              options={processingFeeOptions}
              styles={{ container: "!w-full" }}
              placeholder={"Select processing fee type"}
              onChange={(option: { name: string; value: string }) => {
                setProcessingFeeType(option.value);
              }}
              selectedItems={processingFeeTypeMap[processingFeeType]}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <Input
            type="text"
            name="reason"
            label="Reason"
            styles={{
              label: "text-xs",
              input: "text-xs placeholder:text-xs",
              container: "w-full",
            }}
            placeholder="Enter Reason"
            register={register("reason", {
              required: "required",
            })}
            error={errors.reason}
            errorText={errors.reason?.type}
          />
          <Input
            type="text"
            name="maxEMI"
            label="Max EMI"
            styles={{
              label: "text-xs",
              input: "text-xs placeholder:text-xs",
              container: "w-full",
            }}
            placeholder="Enter Max EMI"
            register={register("maxEMI", {
              required: "required",
              validate: {
                validateAmount: (v) => !isNaN(v) && Number(v) > 0,
              },
            })}
            error={errors.maxEMI}
            errorText={errors.maxEMI?.type}
          />
        </div>
        {processingFeeType === "flat" && (
          <Input
            name="processingFee"
            type="text"
            label="Processing Fee (₹)"
            styles={{
              container: "w-full mb-4 md:mr-4 md:mb-0 my-4",
              input: "text-xs placeholder:text-xs",
              label: "text-xs",
            }}
            placeholder={"Processing Fee ₹ "}
            register={register("processingFeeFlat", {
              required: "required",
              min: 0,
              max: 1000,
              validate: {
                validateProcessingFee: (v) =>
                  !isNaN(v) &&
                  Number(v) >= 0 &&
                  Number(v) < Number(getValues("amount")),
              },
              onChange: (e) => {
                calculateProcessingFee({
                  currentProcessingFeeFlat: e.target.value,
                });
              },
            })}
            error={errors.processingFeeFlat}
            errorText={processingFeeErrorMsg(
              errors?.processingFeeFlat?.type || "",
              "₹1000"
            )}
          />
        )}
        {processingFeeType === "perc" && (
          <Input
            name="processingFee"
            type="text"
            label="Processing Fee (%)"
            styles={{
              container: "w-full mb-4 md:mr-4 md:mb-0 my-4",
              input: "text-xs placeholder:text-xs",
              label: "text-xs",
            }}
            placeholder={"Processing Fee %"}
            register={register("processingFeePerc", {
              required: "required",
              min: 0,
              max: 4,
              validate: {
                validateProcessingFee: (v) =>
                  !isNaN(v) && Number(v) >= 0 && Number(v) < 100,
              },
              onChange: (e) => {
                calculateProcessingFee({
                  currentProcessingFeePerc: e.target.value,
                });
              },
            })}
            error={errors.processingFeePerc}
            errorText={processingFeeErrorMsg(
              errors.processingFeePerc?.type || "",
              "4%"
            )}
          />
        )}
        {processingFeeType === "perc+flat" && (
          <div className="grid grid-cols-2 gap-4 mt-4">
            <Input
              name="processingFee"
              type="text"
              label="Processing Fee (%)"
              styles={{
                container: "w-full",
                input: "text-xs placeholder:text-xs",
                label: "text-xs",
              }}
              placeholder={"Processing Fee %"}
              register={register("processingFeePerc", {
                required: "required",
                min: 0,
                max: 4,
                validate: {
                  validateProcessingFeePerc: (v) =>
                    !isNaN(v) && Number(v) >= 0 && Number(v) < 100,
                },
                onChange: (e) => {
                  calculateProcessingFee({
                    currentProcessingFeePerc: e.target.value,
                  });
                },
              })}
              error={errors.processingFeePerc}
              errorText={processingFeeErrorMsg(
                errors.processingFeePerc?.type || "",
                "4%"
              )}
            />
            <Input
              type="text"
              name="processingFeeComponent"
              label="Additional Flat Processing Fee Component"
              styles={{
                container: "w-full",
                input: "text-xs placeholder:text-xs",
                label: "text-xs",
              }}
              placeholder={"Processing Fee Flat Amount"}
              register={register("processingFeeComponent", {
                required: "required",
                min: 0,
                max: 500,
                value: 0,
                validate: {
                  validateNumber: (v) => !isNaN(v),
                },
                onChange: (e) => {
                  calculateProcessingFee({
                    currentProcessingFeeComponent: e.target.value,
                  });
                },
              })}
              error={errors.processingFeeComponent}
              errorText={processingFeeErrorMsg(
                errors.processingFeeComponent?.type || "",
                "₹500"
              )}
            />
          </div>
        )}
        <div className="flex items-center justify-between mt-4">
          <span className="text-sm font-semibold">
            {" "}
            Total Processing Fee : {getFormattedCurrency(processingFee)}
          </span>
          <div className="flex items-center">
            <button
              type="reset"
              color="default"
              onClick={(e) => {
                e.stopPropagation();
                isOpen(false);
              }}
              className="text-sm mr-4 px-4 py-2 w-auto rounded-lg bg-white border border-gray-300 text-gray-700 flex items-center justify-center font-medium"
            >
              Cancel
            </button>
            <Button
              type="submit"
              color="primary"
              className="text-sm px-4 py-2 w-auto rounded-lg text-white flex items-center justify-center font-medium"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default memo(ManualOfferModal);
