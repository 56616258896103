import { notify } from "../../shared/context/AlertContext";
import { Get, Post, getTokenFromStorage } from "../../shared/services/Fetch";
import { ServiceMethodArgs } from "../LoanApplication/interfaces";
import { noHeadersResponse } from "../../shared/utils/Global";
import { DashboardUserDTO } from "../../dtos/DashboardUserDTO";

export class UserService {
  async getPermissions(tok: string) {
    try {
      const token = tok || getTokenFromStorage();
      const headers = new Headers();
      headers.append("page-header", "Login");
      if (token) {
        const response = await Get<DashboardUserDTO>(
          `/masterDashboard/getPermissions`,
          token,
          headers
        );
        if (response.status) {
          return response.data;
        } else {
          notify({
            text: response.error,
            title: "Session Expired",
          });
          return null;
        }
      }

      notify({
        text: "User not logged in!",
        title: "Session Expired",
      });
      return null;
    } catch (err: any) {
      notify({
        title: "Permission Error",
        text: new Error(err).message,
      });
      return null;
    }
  }

  async loginEmail({
    email,
    password,
    mfaCode,
    organizationID,
  }: {
    email: string;
    password?: string;
    mfaCode?: string;
    organizationID?: string;
  }) {
    try {
      const payload = {
        email,
        password,
        mfaCode,
      };
      if (organizationID) {
        payload.organizationID = organizationID;
      }
      const response = await Post("/masterDashboard/login", "", payload);
      return response;
    } catch (err: any) {
      return {
        data: null,
        error: "Something went wrong. Please try after sometime",
        status: false,
      };
    }
  }

  async loginSSO(payload: {
    appID: string;
    email: string;
    ssoProvider: string;
    organizationID: string;
  }) {
    const requestop = {
      credentials: "include",
    };
    var myHeaders = new Headers();

    try {
      return await Post(
        "/masterDashboard/login-sso",
        "",
        payload,
        myHeaders,
        false,
        requestop
      );
    } catch (err: any) {
      return {
        data: null,
        error: "Something went wrong. Please try after sometime",
        status: false,
      };
    }
  }

  async verifySSOUser(payload: {
    userID: string;
    sessionID: string;
    organizationID: string;
  }) {
    try {
      return await Post("/masterDashboard/generate-token", "", payload);
    } catch (err: any) {
      return {
        data: null,
        error: "Something went wrong. Please try after sometime",
        status: false,
      };
    }
  }

  async loginOTP({
    mobile,
    organizationID,
  }: {
    mobile: string;
    organizationID: string;
  }) {
    try {
      const payload: Record<string, string> = {
        mobile,
      };
      if (organizationID) {
        payload.organizationID = organizationID;
      }
      const response = await Post("/masterDashboard/loginOTP", "", payload);
      return response;
    } catch (err: any) {
      return {
        data: null,
        error: "Something went wrong. Please try after sometime",
        status: false,
      };
    }
  }

  async logout() {
    try {
      const resp = await Post(
        "/masterDashboard/logout",
        getTokenFromStorage(),
        {}
      );
      if (resp.status) {
        console.log("Logging out...");
      } else {
        // do not show notification when user logout fails due to invalid token
        // notify({
        //   title: "Logout Error",
        //   text: resp.error,
        // });
      }
    } catch (err: any) {
      notify({
        title: "Logout Error",
        text: new Error(err).message,
      });
    }
  }

  async updateProfileOTP({
    mobile,
    token,
    pageName,
  }: ServiceMethodArgs<{ mobile: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/updateProfileOTP`,
      token,
      {
        mobile,
      },
      headers
    );
  }

  async updateUserProfile({
    mobile,
    token,
    otp,
    name,
    pageName,
  }: ServiceMethodArgs<{
    mobile?: string;
    otp?: string;
    name: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/updateUserProfile`,
      token,
      {
        mobile,
        otp,
        name,
      },
      headers
    );
  }

  async sendForgetPasswordLink({
    email,
    mobile,
    organizationID = "",
  }: {
    email: string;
    mobile?: string;
    organizationID?: string;
  }) {
    return await Post("/masterDashboard/sendForgetPasswordLink", "", {
      email,
      organizationID,
    });
  }

  async resetPassword({
    password,
    email,
    organizationID = "",
  }: {
    password: string;
    email: string;
    organizationID?: string;
  }) {
    return await Post("/masterDashboard/resetPassword", "", {
      password,
      email,
      organizationID,
    });
  }

  async changePassword({
    password,
    token,
    pageName,
  }: ServiceMethodArgs<{
    password: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      "/masterDashboard/changePassword",
      token,
      {
        password,
      },
      headers
    );
  }

  async sendChangePasswordLink({
    mobile,
    email,
    token,
    pageName,
  }: ServiceMethodArgs<{
    mobile: string;
    email: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      "/masterDashboard/sendChangePasswordLink",
      token,
      {
        mobile,
        email,
      },
      headers
    );
  }

  async passwordExpire({
    oldPassword,
    newPassword,
    email,
    organizationID = "",
  }: {
    oldPassword: string;
    newPassword: string;
    email: string;
    organizationID?: string;
  }) {
    try {
      const response = await Post("/masterDashboard/resetExpiredPassword", "", {
        oldPassword,
        newPassword,
        email,
        organizationID,
      });
      return response;
    } catch (err: any) {
      return {
        data: null,
        error: "Something went wrong. Please try after sometime",
        status: false,
      };
    }
  }

  async refetchMFADetails({
    token,
    recoveryKey,
  }: ServiceMethodArgs<{ recoveryKey: string }>) {
    return await Post("/masterDashboard/refetchMFADetails", token, {
      recoveryKey,
    });
  }

  async mfaSetup({ token }: ServiceMethodArgs<{}>) {
    return await Post(`/masterDashboard/mfaSetup`, token, {});
  }

  async authenticateMFA({
    token,
    code1,
    code2,
  }: ServiceMethodArgs<{ code1: string; code2: string }>) {
    return await Post(`/masterDashboard/authenticateMFA`, token, {
      code1,
      code2,
    });
  }

  async sendLoginOTPOnEmail({
    email,
    token,
  }: ServiceMethodArgs<{ email: string }>) {
    return await Post(`/masterDashboard/sendLoginOTPOnEmail`, token, {
      email,
    });
  }
  async verifyMobilePassword({
    mobile,
    password,
    token,
  }: ServiceMethodArgs<{ mobile: string; password: string }>) {
    return await Post(`/masterDashboard/verifyMobilePassword`, token, {
      mobile,
      password,
    });
  }

  async sendLoginOTPOnMobile({
    token,
    mobile,
  }: ServiceMethodArgs<{ mobile: string }>) {
    return await Post(`/masterDashboard/sendLoginOTPOnMobile`, token, {
      mobile,
    });
  }
}
