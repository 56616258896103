import { format } from "date-fns";
import classNames from "classnames";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  User01,
  Percent03,
  ShieldZap,
  PhoneCall01,
  AlignJustify,
  CalendarHeart02,
  CreditCardShield,
} from "@untitled-ui/icons-react";

import { CardLabel } from "./CardLabel";
import { DirectorType } from "../interface";

export const STATUS = {
  USER_QUALIFIED: {
    text: "Qualified",
    class: "bg-[#ECFDF3] text-[#039855]",
  },
  USER_CREATED: {
    text: "User Created",
    class: "bg-[#D5D6F6] text-[#2E30C9]",
  },
  USER_PROFILE_UPDATED: {
    text: "User Profile Updated",
    class: "bg-[#F6F2D5] text-[#C9B92E]",
  },
  OTP_VERIFIED: {
    text: "OTP Verified",
    class: "bg-[#C3DAFE] text-[#3182CE]",
  },
  USER_DISQUALIFIED: {
    text: "Disqualified",
    class: "bg-[#FED7D7] text-[#EF4444]",
  },
} as const;

export const GENDER = {
  0: "Female",
  1: "Male",
} as const;

export const Card = ({
  director,
  pageName,
  setViewInfo,
}: {
  director: DirectorType;
  pageName: string;
  setViewInfo: (args: any) => void;
}) => {
  const sidebarHandler = () => {
    setViewInfo({
      ...director,
      ...JSON.parse(director?.currentAddress || "{}"),
    });
  };

  return (
    <div className="border shadow-sm py-5 px-6 mt-6 bg-white rounded-xl">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="text-[#101828] text-lg font-semibold">
            {director?.name}
          </div>
          {!!director?.status && (
            <div
              className={classNames(
                "text-xs font-normal px-2 py-1 capitalize ml-2 rounded-full",
                STATUS?.[director?.status]?.class
              )}
            >
              {STATUS?.[director?.status]?.text}
            </div>
          )}
          {director?.active && (
            <div className="ml-2 bg-[#DEEAF8] text-[#1982FF] text-xs font-normal px-2 py-1 rounded-full">
              Included in the loan application
            </div>
          )}
        </div>
        <div className="flex items-center">
          <button
            onClick={sidebarHandler}
            className="bg-transparent flex items-center border-none text-[#1982FF] text-sm font-medium w-full"
          >
            See all details
            <ChevronRightIcon className="h-5 w-5 text-inherit ml-2" />
          </button>
        </div>
      </div>
      <div className="text-[#475467] font-normal text-sm mt-1">
        {director?.designation}
      </div>
      <div className="mt-10">
        <div className="grid grid-cols-2 gap-y-5">
          <CardLabel
            label="PAN number"
            value={director?.pan || "-"}
            icon={<CreditCardShield className="text-[#4C4C4D]" />}
          />
          <CardLabel
            label="Shareholding Percentage"
            value={director?.shareHoldingPercentage + "%"}
            icon={<Percent03 className="text-[#4C4C4D]" />}
          />
          <CardLabel
            label="Contact Info"
            icon={<PhoneCall01 className="text-[#4C4C4D]" />}
            value={
              director?.mobile || director?.email
                ? `${director?.mobile} ${director?.email ? "|" : ""} ${
                    director?.email
                  } `
                : "-"
            }
          />
          <CardLabel
            label="Identification Number"
            value={director?.identificationNumber ?? "-"}
            icon={<ShieldZap className="text-[#4C4C4D]" />}
          />
          <CardLabel
            label="Date of birth"
            icon={<CalendarHeart02 className="text-[#4C4C4D]" />}
            value={
              director?.dob
                ? format(new Date(director?.dob), "dd MMM, yyyy")
                : "-"
            }
          />
          <CardLabel
            label="Gender"
            value={GENDER[director?.gender] ?? "-"}
            icon={<User01 className="text-[#4C4C4D]" />}
          />
          <CardLabel
            label="Customer Number Individual"
            value={director?.cifNumberIndividual ?? "-"}
            icon={<AlignJustify className="text-[#4C4C4D]" />}
          />
        </div>
      </div>
    </div>
  );
};
