import classNames from "classnames";
import { InputHTMLAttributes, ReactNode } from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

import Label from "../../atomic/Label";
import { ErrorTextBox } from "../../atomic/ErrorBox";

interface IProps
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  styles?: {
    container?: string;
    label?: string;
    input?: string;
    textarea?: string;
  };
  label?: ReactNode;
  register?: any;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  errorText?: string;
  requiredIcon?: boolean;
  pattern?: string;
  autoComplete?: AutoFill | undefined;
}

export default function Input({
  styles = {},
  label,
  name = "",
  type,
  autoComplete = "off",
  requiredIcon = false,
  onChange = undefined,
  value,
  placeholder,
  register,
  error,
  disabled = false,
  errorText = "",
  multiple = false,
  pattern = undefined,
  ...rest
}: IProps) {
  return (
    <div
      className={classNames(
        "",
        styles.container ? styles.container : "sm:col-span-4"
      )}
    >
      {label && (
        <Label
          name={name}
          label={label}
          style={styles.label}
          requiredIcon={requiredIcon}
        />
      )}
      {multiple ? (
        <div>
          <textarea
            id={name || undefined}
            type={type}
            value={value}
            disabled={disabled}
            autoComplete={autoComplete}
            className={classNames(
              "block w-full rounded-md border-gray-200 sm:text-sm lg:text-xs px-4 py-2",
              disabled && "cursor-not-allowed",
              styles.textarea || ""
            )}
            placeholder={placeholder}
            onChange={(e) => {
              e.stopPropagation();
              onChange?.(e);
            }}
            rows={5}
            spellCheck={false}
            {...register}
            {...rest}
          />
          {errorText ? <ErrorTextBox text={errorText}></ErrorTextBox> : null}
        </div>
      ) : (
        <div>
          <input
            id={name || undefined}
            type={type}
            value={value}
            disabled={disabled}
            autoComplete={autoComplete}
            className={classNames(
              "block w-full rounded-md border-gray-200 sm:text-sm lg:text-xs px-4 py-2 placeholder:text-[#CECFCF]",
              disabled && "cursor-not-allowed !bg-[#F0F0F1]",
              styles.input || ""
            )}
            placeholder={placeholder}
            onChange={(e) => {
              e.stopPropagation();
              onChange?.(e);
            }}
            spellCheck={false}
            {...register}
            {...rest}
          />
          {errorText ? <ErrorTextBox text={errorText}></ErrorTextBox> : null}
        </div>
      )}
    </div>
  );
}
