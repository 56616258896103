import { LoanApplicationService } from "../../services/LoanApplication";

export async function loader({ params, getToken, pageName }: any) {
  const { userID } = params;

  const payload = {
    userID,
    pageName,
    token: getToken(),
  };

  const response = (await LoanApplicationService.listBankConnectAttempts(
    payload
  )) as any;

  if (
    typeof response?.data === "object" &&
    Object.keys(response?.data)?.length === 0
  ) {
    return { bankStatementResponse: [] };
  }

  return { bankStatementResponse: response?.data || [] };
}
