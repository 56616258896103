import { ReactNode } from "react";
import classNames from "classnames";
import { useOutletContext } from "react-router";

import Button from "../atomic/Button";

type Props = {
  title: string;
  icon?: ReactNode;
  isDisabled?: boolean;
  onClick: () => void;
  tooltip?: {
    id: string;
    message: string;
  };
};

export default function ActionButton({
  title,
  icon,
  onClick,
  tooltip,
  isDisabled = false,
}: Props) {
  const outletContxt = useOutletContext() as any;
  const { whiteLabelStyle } = outletContxt ?? {};
  return (
    <>
      <Button
        onClick={onClick}
        disabled={isDisabled}
        className={classNames(
          "w-full flex items-center justify-start truncate bg-white text-[#626262] text-sm font-medium  hover:text-white",
          whiteLabelStyle?.theme?.hover?.darkbg1 ||
            "hover:bg-indigo-700 hover:ring-indigo-700"
        )}
      >
        {!!icon && <span className="w-max">{icon}</span>}
        {title}
      </Button>
    </>
  );
}
