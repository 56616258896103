import GetAuthContext from "../../../shared/context/AuthContext";
import { ErrorTextBox } from "../../atomic/ErrorBox";
import { useState } from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";

import { notify } from "../../../shared/context/AlertContext";
import { CommonService } from "../../../services/Common";
import { LoanApplicationService } from "../../../services/LoanApplication";
import FileUploadInput from "../FormElements/FileUploadInput";
import DropdownWithSearch from "../../atomic/DropdownWithSearch";
import { DropdownWithSearchOption } from "../../atomic/DropdownWithSearch/interface";
import Button from "../../atomic/Button";
import TextArea from "../../atomic/TextArea";

interface IProps {
  setOpen: React.Dispatch<boolean>;
  userID: string;
  loanApplictionID: string;
  reload: Function;
  pageName?: string;
  workflowRequests?: Array<DropdownWithSearchOption>;
}

export default function AddAdditionalDocsModal({
  setOpen,
  userID,
  loanApplictionID,
  reload,
  pageName,
  workflowRequests,
}: IProps): JSX.Element {
  const [files, setFiles] = useState([]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    control,
    setValue,
    getValues,
    clearErrors,
  } = useForm();
  const { fetchToken } = GetAuthContext();

  async function uploadMediaFiles() {
    let uploadPromises = [];
    let token = fetchToken();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let formData = new FormData();
      formData.append("file", file);
      formData.append("mediaType", "Additional_documents");
      formData.append("userID", userID);
      uploadPromises.push(
        CommonService.uploadDashboardMedia({
          token,
          formData,
          pageName,
        })
      );
    }
    let mediaIDs = [];
    try {
      const responses = await Promise.all(uploadPromises);
      responses?.map((response) => {
        if (response.status && response.data?.mediaID) {
          mediaIDs.push(response.data.mediaID);
        } else {
          notify({
            title: "Error",
            text: response.error,
          });
        }
      });
    } catch (error) {
      notify({
        title: "Error",
        text: new Error(error).message,
      });
    }

    return mediaIDs;
  }

  async function onSubmit(data: any) {
    if (!files.length) {
      setError("file", {
        type: "required",
        message: "Upload Documents is required.",
      });
      return;
    }

    try {
      const mediaIDs = await uploadMediaFiles();
      if (mediaIDs.length > 0) {
        let formData = {};
        formData["mediaIDs"] = mediaIDs;
        formData["comment"] = data.comment;
        formData["loanApplicationID"] = loanApplictionID;
        formData["eventType"] = data.selectedWorkflowRequest.value;
        formData["workflowName"] = data.selectedWorkflowRequest.workflowName;
        formData["targetGroup"] = data.selectedWorkflowRequest.targetGroup;
        
        let response = await LoanApplicationService.uploadDocs({
          token: fetchToken(),
          formData,
          pageName,
        });
        if (response.status) {
          notify({
            type: "success",
            text: "Documents Uploaded!",
            title: "Success",
          });
          reload();
          setOpen(false);
        } else {
          notify({
            title: "Document Upload Failed",
            text: response.error,
          });
        }
      }
    } catch (error: any) {
      notify({
        title: "Error",
        text: new Error(error.toString()).message,
      });
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {workflowRequests && workflowRequests.length && (
          <fieldset>
            <DropdownWithSearch
              label={
                <>
                  Select Request <span className="text-red-400">*</span>
                </>
              }
              styles={{ container: "!w-full" }}
              options={workflowRequests}
              placeholder={"Select workflow request"}
              onChange={(selectedItem: DropdownWithSearchOption) => {
                setValue("selectedWorkflowRequest", selectedItem);
                clearErrors("selectedWorkflowRequest");
              }}
              selectedItems={getValues("selectedWorkflowRequest")}
              register={register("selectedWorkflowRequest", {
                required: true,
              })}
            />
            <ErrorTextBox
              text={
                errors.selectedWorkflowRequest?.type === "required"
                  ? "required"
                  : ""
              }
            />
          </fieldset>
        )}

        <fieldset
          className={classNames(workflowRequests?.length ? "mt-6" : "mt-0")}
        >
          {control ? (
            <>
              <FileUploadInput
                isRequired={true}
                accept={{
                  "image/*": [".jpeg", ".png", ".jpg"],
                  "application/pdf": [".pdf"],
                }}
                maxFiles={5}
                onDropAccepted={(data) => {
                  setFiles(data);
                }}
                onDropRejected={() => {
                  setError("file", {
                    type: "validate",
                    message:
                      "File upload rejected. Please check the file format or try again.",
                  });
                }}
                outsideView
                control={control}
                setValue={setValue}
                label={"Upload Documents"}
                name="file"
                styles={{
                  inputContainer:
                    "bg-white !h-[130px] !min-h-[130px] !border-solid",
                }}
                customText={
                  <>
                    <div className="flex flex-col items-center">
                      <div className="rounded h-7 w-7 border flex justify-center items-center">
                        <CloudArrowUpIcon className="h-5 w-5 text-[#475467]" />
                      </div>
                      <div className="text-sm mt-2">
                        <span className="text-indigo-600 text-semibold">
                          Click to upload
                        </span>{" "}
                        <span className="text-[#475467]">or drag and drop</span>
                      </div>
                      <div className="text-xs text-[#475467] mt-0.5">
                        Supported Formats: PDF, PNG, JPG, JPEG, Maximum 5
                      </div>
                    </div>
                  </>
                }
              />
            </>
          ) : null}
          <ErrorTextBox
            text={
              errors.file?.type === "validate" ? "Max Upload limit is 5" : ""
            }
          />
        </fieldset>
        <fieldset className="mt-6">
          <TextArea
            name="comment"
            label="Comment"
            styles={{
              container: "w-full",
              input: "text-xs placeholder:text-xs",
              label: "text-xs",
            }}
            placeholder={"Comments..."}
            register={register("comment", {
              required: false,
            })}
            error={errors.comment}
          />
        </fieldset>
        <div className="flex justify-end">
          <Button
            type="submit"
            color="primary"
            className="mt-6"
          >
            Upload
          </Button>
        </div>
      </form>
    </div>
  );
}
