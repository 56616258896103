import classNames from "classnames";
import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useLoaderData, useOutletContext, useParams } from "react-router-dom";

import { Get } from "../../shared/services/Fetch";
import CopyText from "../../components/atomic/CopyText";
import Divider from "../../components/atomic/Divider";
import { notify } from "../../shared/context/AlertContext";
import { DialogOne } from "../../components/atomic/Dialog";
import { ACTIONS_LIST } from "../../shared/utils/constants";
import GetAuthContext from "../../shared/context/AuthContext";
import { Accordion } from "../../components/atomic/Accordion";
import { getFormattedDate } from "../../shared/utils/DateUtil";
import PrivateComponent from "../../components/composed/PrivateComponent";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import GenerateEMandateModal from "../../components/composed/GenerateEMandateModal";

import {
  noHeadersResponse,
  getFormattedCurrency,
} from "../../shared/utils/Global";
import Button from "../../components/atomic/Button";

export async function loanPaymentsLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID } = params;
  const headers = new Headers();
  if (pageName) {
    headers.append("page-header", pageName);
  } else {
    return noHeadersResponse();
  }
  const token = getToken();
  return Promise.all([
    await Get(
      `/masterDashboard/payments?loanApplicationID=${loanID}`,
      token,
      headers
    ),
    await Get(
      `/masterDashboard/getVirtualAccountDetails?loanApplicationID=${loanID}`,
      token,
      headers
    ),
  ]);
}

const getImageExtensionFromURL = (imagePath: string) => {
  return imagePath?.split(/[#?]/)?.[0]?.split(".")?.pop()?.trim();
};

const isImage = (extension: string) => {
  if (!extension || extension.length === 0) return false;
  return ["png", "jpg", "jpeg"].includes(extension);
};

const genPaymentLink = (loanPaymentID: string, loanID: string) => {
  let urlSearchParams = new URLSearchParams();
  if (loanID) {
    urlSearchParams.append("loanApplicationID", loanID);
  }
  if (loanPaymentID) {
    urlSearchParams.append("loanPaymentID", loanPaymentID);
  }
  urlSearchParams.append("hideStarter", false);
  let url =
    import.meta.env.REACT_APP_API_ENDPOINT +
    `/payment/webviewPaymentInitiate?${urlSearchParams.toString()}`;
  return url;
};

const MandateCard = ({ autoPay }) => {
  return (
    <div className="md:border md:border-gray-200 rounded-lg p-4 mb-4">
      <div className="flex flex-col flex-wrap gap-x-4 h-[160px]">
        <div className="w-1/2  md:w-1/3 mb-4">
          <label
            className="block text-xs font-medium text-gray-700 mb-1"
            htmlFor=""
          >
            Account Number
          </label>
          <p className="text-xs text-gray-500">{autoPay.accountNumber}</p>
        </div>
        <div className="w-1/2 md:w-1/3 mb-4">
          <label
            className="block text-xs font-medium text-gray-700 mb-1"
            htmlFor=""
          >
            Status
          </label>
          <p className="text-xs text-gray-500">{autoPay.status}</p>
        </div>
        <div className="w-1/2 md:w-1/3 mb-4">
          <label
            className="block text-xs font-medium text-gray-700 mb-1"
            htmlFor=""
          >
            Vendor
          </label>
          <p className="text-xs text-gray-500">{autoPay.vendorName}</p>
        </div>
        <div className="w-1/2 md:w-1/3 mb-4">
          <label
            className="block text-xs font-medium text-gray-700 mb-1"
            htmlFor=""
          >
            Mandate ID
          </label>
          <p className="text-xs text-gray-500 truncate">{autoPay.mandateId}</p>
        </div>
        <div className="w-1/2 md:w-1/3 mb-4">
          <label
            className="block text-xs font-medium text-gray-700 mb-1"
            htmlFor=""
          >
            Mode
          </label>
          <p className="text-xs text-gray-500">{autoPay.mode}</p>
        </div>
      </div>

      {autoPay.docs && autoPay.docs.length > 0 && (
        <>
          <label
            className="block text-xs font-medium text-gray-700 mb-1"
            htmlFor=""
          >
            Docs
          </label>

          <div className="flex flex-row flex-wrap">
            {autoPay.docs
              .filter((doc) => doc?.preview)
              .map((doc) => (
                <div className="md:w-1/3 w-1/2  p-2 mb-2">
                  <div className="relative w-full md:h-[250px] h-[150px] flex items-center justify-center overflow-hidden border border-gray-200 rounded-md">
                    {!isImage(getImageExtensionFromURL(doc?.url)!) ? (
                      <span
                        onClick={() => window.open(doc?.url, "_blank")}
                        className="flex-grow w-full flex align-center justify-center hover:bg-slate-300 items-center h-full"
                      >
                        Click to download
                      </span>
                    ) : (
                      <img
                        className="flex-grow object-cover"
                        src={doc?.url}
                        alt={doc?.name}
                      />
                    )}
                  </div>
                  <span className="flex justify-center text-xs mt-px">
                    {doc?.name}
                  </span>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

const PaymentCard = ({ payment, loanApplicationID }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="border-b md:border md:border-gray-200 md:rounded-lg p-4 mb-4">
      <div className="flex flex-row w-full h-full">
        <div className="flex flex-col flex-wrap w-full gap-x-4 h-[160px]">
          <div className="w-1/2 md:w-1/3 mb-4">
            <label
              className="block text-xs font-medium text-gray-700 mb-1"
              htmlFor=""
            >
              Amount
            </label>
            <p className="text-xs text-gray-500">
              {getFormattedCurrency(payment.amount)}
            </p>
          </div>
          <div className="w-1/2 md:w-1/3 mb-4">
            <label
              className="block text-xs font-medium text-gray-700 mb-1"
              htmlFor=""
            >
              Status
            </label>
            <p className="text-xs text-gray-500">{payment.status}</p>
          </div>
          <div className="w-1/2 md:w-1/3 mb-4">
            <label
              className="block text-xs font-medium text-gray-700 mb-1"
              htmlFor=""
            >
              Due Date
            </label>
            <p className="text-xs text-gray-500">
              {payment.dueDate
                ? format(parseISO(payment.dueDate), "dd MMM, yyyy")
                : "-"}
            </p>
          </div>
          <div className="w-1/2 md:w-1/3 mb-4">
            <label
              className="block text-xs font-medium text-gray-700 mb-1"
              htmlFor=""
            >
              Amount Received
            </label>
            <p className="text-xs text-gray-500">
              {getFormattedCurrency(payment.amountReceived)}
            </p>
          </div>
          <div className="w-1/2 md:w-1/3 mb-4">
            <label
              className="block text-xs font-medium text-gray-700 mb-1"
              htmlFor=""
            >
              Installment Number
            </label>
            <p className="text-xs text-gray-500 truncate">
              {payment.installmentNum}
            </p>
          </div>
        </div>
        <div
          className="w-5"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <ChevronUpIcon className="w-5 h-5" />
          ) : (
            <ChevronDownIcon className="w-5 h-5" />
          )}
        </div>
      </div>
      {isExpanded && (
        <>
          <Divider />
          <div className="flex flex-col flex-wrap w-full gap-x-4 h-[160px] mt-5">
            <div className="w-1/2 md:w-1/3 mb-4">
              <label
                className="block text-xs font-medium text-gray-700 mb-1"
                htmlFor=""
              >
                Loan Payment ID
              </label>
              <p className="text-xs text-gray-500">{payment.loanPaymentId}</p>
            </div>
            <div className="w-1/2 md:w-1/3 mb-4">
              <label
                className="block text-xs font-medium text-gray-700 mb-1"
                htmlFor=""
              >
                Late Charge
              </label>
              <p className="text-xs text-gray-500">
                {getFormattedCurrency(payment.lateCharge)}
              </p>
            </div>
            <div className="w-1/2 md:w-1/3 mb-4">
              <label
                className="block text-xs font-medium text-gray-700 mb-1"
                htmlFor=""
              >
                Paid Date
              </label>
              <p className="text-xs text-gray-500">
                {payment.paidDate ? getFormattedDate(payment.paidDate) : "-"}
              </p>
            </div>
            <div className="w-1/2 md:w-1/3 mb-4">
              <label
                className="block text-xs font-medium text-gray-700 mb-1"
                htmlFor=""
              >
                Source
              </label>
              <p className="text-xs text-gray-500">
                {payment.source ? payment.source : "-"}
              </p>
            </div>
            <div className="w-1/2 md:w-1/3 mb-4">
              <label
                className="block text-xs font-medium text-gray-700 mb-1"
                htmlFor=""
              >
                Transaction ID
              </label>
              <p className="text-xs text-gray-500 truncate">
                {payment.transactionId ? payment.transactionId : "-"}
              </p>
            </div>
          </div>
          <div>
            <span className="text-xs font-bold">Payment Link</span>
            <CopyText
              styles={{
                container: "mb-4 mt-4",
                input:
                  "w-full border border-dashed border-gray-300 rounded-tl-lg rounded-bl-lg bg-gray-100 !h-10",
                button: "bg-gray-200 text-gray-700 border-gray-200 !h-10",
              }}
              text={genPaymentLink(payment.loanPaymentId, loanApplicationID)}
            />
          </div>
          <div>
            <span className="text-xs font-bold mb-4">Charges</span>
            <div className="flex flex-col flex-wrap w-full gap-x-4 h-[160px] mt-5">
              {payment.charges.map((charge) => (
                <div className="w-1/2 md:w-1/3 mb-4">
                  <label
                    className="block text-xs font-medium text-gray-700 mb-1"
                    htmlFor=""
                  >
                    {charge.chargeType
                      .split("_")
                      .map((part) => part[0] + part.substring(1).toLowerCase())
                      .join(" ")}
                  </label>
                  <p className="text-xs text-gray-500">
                    {getFormattedCurrency(charge.amount)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const VirtualAccountCard = ({ virtualAccountDetails }) => {
  return (
    <div className="border-b md:border md:border-gray-200 md:rounded-lg p-4">
      <div className="flex flex-col flex-wrap gap-x-4 h-[160px]">
        <div className="w-1/2 md:w-1/3 mb-4">
          <label
            className="block text-xs font-medium text-gray-700 mb-1"
            htmlFor=""
          >
            Account Holder Name
          </label>
          <p className="text-xs text-gray-500">
            {virtualAccountDetails.accountHolderName}
          </p>
        </div>
        <div className="w-1/2 md:w-1/3 mb-4">
          <label
            className="block text-xs font-medium text-gray-700 mb-1"
            htmlFor=""
          >
            Account Number
          </label>
          <p className="text-xs text-gray-500">
            {virtualAccountDetails.accountNumber}
          </p>
        </div>
        <div className="w-1/2 md:w-1/3 mb-4">
          <label
            className="block text-xs font-medium text-gray-700 mb-1"
            htmlFor=""
          >
            Bank Name
          </label>
          <p className="text-xs text-gray-500">
            {virtualAccountDetails.bankName}
          </p>
        </div>
        <div className="w-1/2 md:w-1/3 mb-4">
          <label
            className="block text-xs font-medium text-gray-700 mb-1"
            htmlFor=""
          >
            IFSC
          </label>
          <p className="text-xs text-gray-500">{virtualAccountDetails.ifsc}</p>
        </div>
      </div>
    </div>
  );
};

function LoanPayments({ pageName }: { pageName?: string }) {
  // @ts-ignore
  const [paymentData, virtualAccountDetails] = useLoaderData();
  const [showBtn, setShowBtn] = useState<boolean>(true);
  const [showPDCBtn, setShowPDCBtn] = useState<boolean>(false);
  const [showEMandateModal, setShowEMandateModal] = useState<boolean>(false);
  const [eMandateLink, setEMandateLink] = useState<string>("");
  const { loanID, sourceEntityID } = useParams();
  const { fetchToken } = GetAuthContext();
  const { loanDetails, whiteLabelStyle } = useOutletContext() as any;
  const sourceEntityName = loanDetails.sourceEntityName;
  useEffect(() => {
    if (paymentData && paymentData.data.autopayList) {
      if (paymentData.data.autopayList.length > 0) {
        const enachMandate = paymentData.data.autopayList.find(
          (payment) =>
            payment.mode === "cashfree_enach" || payment.mode === "digio_enach"
        );

        if (enachMandate) {
          if (
            enachMandate.status === "ACTIVE" ||
            enachMandate.status === "ON_HOLD" ||
            enachMandate.status === "COMPLETED" ||
            enachMandate.status === "AUTH_SUCCESS"
          ) {
            setShowBtn(false);
          }
        }
      }
    }
  }, [paymentData]);

  useEffect(() => {
    if (
      paymentData?.data &&
      paymentData?.data.autopayList &&
      sourceEntityName === "Khatabook"
    ) {
      if (paymentData?.data.autopayList.length > 0) {
        const physicalMandate = paymentData?.data.autopayList.find(
          (x) => x.mode === "physical_mandate"
        );
        const pdcAvailable = paymentData?.data.autopayList.find(
          (x) => x.mode === "pdc"
        );
        if (physicalMandate && !pdcAvailable) {
          if (physicalMandate.status === "SIGNED") {
            setShowPDCBtn(true);
          }
        }
      }
    }
  }, [paymentData]);

  async function onGenerateLinkClick() {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const response = await Get<{ inviteURL: string }>(
      `/masterDashboard/generateEmandateUrl?loanApplicationID=${loanID}&mandateType=`,
      fetchToken(),
      headers
    );

    if (response.status) {
      setEMandateLink(response.data.inviteURL);
      setShowEMandateModal(true);
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  }

  const isMobile = window.innerWidth < 640;

  if (paymentData.status) {
    const { autopayList, payments } = paymentData.data;
    return (
      <>
        <div className="px-4 overflow-auto hidden md:block">
          <div className="flex items-center text-base font-medium">
            <h3
              className={classNames(
                "text-sm text-gray-700 font-medium w-max border-b-2 py-2 pr-2",
                whiteLabelStyle?.theme?.color?.border || "border-indigo-500"
              )}
            >
              Payments
            </h3>
          </div>
          <div className="mb-6 flex items-center">
            {showBtn && (
              <PrivateComponent
                requiredPage={pageName!}
                sourceEntityId={sourceEntityID}
                requiredFunctions={[ACTIONS_LIST.GENERATE_EMANDATE_LINK]}
              >
                <Button
                  color="primary"
                  className="mt-8"
                  onClick={onGenerateLinkClick}
                >
                  Generate E-Mandate Link
                </Button>
              </PrivateComponent>
            )}
            {showPDCBtn && (
              <PrivateComponent
                requiredPage={pageName!}
                sourceEntityId={sourceEntityID}
                requiredFunctions={[ACTIONS_LIST.GENERATE_EMANDATE_LINK]}
              >
                <Button
                  color="primary"
                  className="mt-8 ml-3"
                  onClick={onGenerateLinkClick}
                >
                  Add PDC Details
                </Button>
              </PrivateComponent>
            )}
          </div>

          {virtualAccountDetails?.data &&
          Object.keys(virtualAccountDetails.data)?.length > 0 ? (
            <div className="overflow-auto hidden md:block mb-6">
              <div className="flex items-center text-base font-medium">
                <h3
                  className={classNames(
                    "text-sm text-gray-700 font-medium w-max border-b-2  py-2 pr-2 mb-4",
                    whiteLabelStyle?.theme?.color?.border || "border-indigo-500"
                  )}
                >
                  Virtual Accounts
                </h3>
              </div>
              <VirtualAccountCard
                virtualAccountDetails={virtualAccountDetails.data}
              />
            </div>
          ) : null}
          {autopayList?.length > 0 && !isMobile ? (
            <div className="mb-6">
              <h3
                className={classNames(
                  "text-sm text-gray-700 font-medium w-max border-b-2  py-2 pr-2 mb-4",
                  whiteLabelStyle?.theme?.color?.border || "border-indigo-500"
                )}
              >
                Mandates
              </h3>
              {autopayList.map((autoPay, autoPayIdx) => {
                return (
                  <MandateCard
                    autoPay={autoPay}
                    key={autoPayIdx}
                  />
                );
              })}
            </div>
          ) : null}

          {payments?.length > 0 && !isMobile ? (
            <div className="mb-6">
              <h3
                className={classNames(
                  "text-sm text-gray-700 font-medium w-max border-b-2  py-2 pr-2 mb-4",
                  whiteLabelStyle?.theme?.color?.border || "border-indigo-500"
                )}
              >
                Loan Payments
              </h3>
              {payments?.filter((e) => e.status === "UNPAID").length > 0 && (
                <>
                  <div className="text-sm mb-2">Payment Link</div>
                  <CopyText
                    styles={{
                      container: "mb-4",
                      input:
                        "w-full border border-dashed border-gray-300 rounded-tl-lg rounded-bl-lg bg-gray-100 !h-10",
                      button: "bg-gray-200 text-gray-700 border-gray-200 !h-10",
                    }}
                    text={`${
                      import.meta.env.REACT_APP_API_ENDPOINT
                    }/payment/list?loanApplicationID=${loanID}`}
                  />
                </>
              )}
              {payments?.map((payment, paymentIdx: number) => {
                return (
                  <PaymentCard
                    payment={payment}
                    key={paymentIdx}
                    loanApplicationID={loanID}
                  />
                );
              })}
            </div>
          ) : null}
        </div>

        <div className="block md:hidden">
          <Accordion
            title="Mandates"
            styles={{
              container: "block border border-gray-200 rounded-lg mb-4",
              button:
                "px-4 py-2 flex items-center justify-between w-full text-sm font-medium",
            }}
            defaultOpen={true}
          >
            {autopayList?.length > 0 ? (
              <div className="">
                {autopayList.map((autoPay, autoPayIdx) => {
                  return (
                    <MandateCard
                      autoPay={autoPay}
                      key={autoPayIdx}
                    />
                  );
                })}
              </div>
            ) : (
              <p>No mandates available</p>
            )}
          </Accordion>

          <Accordion
            title="Payments"
            styles={{
              container: "block border border-gray-200 rounded-lg mb-4",
              button:
                "px-4 py-2 flex items-center justify-between w-full text-sm font-medium",
            }}
            defaultOpen={false}
          >
            {payments?.length > 0 ? (
              <div className="mb-6">
                {payments?.filter((e) => e.status === "UNPAID").length > 0 && (
                  <div className="border-b border-gray-200 pb-2">
                    <div className="text-xs font-medium mb-2 px-4 text-gray-700 mt-2">
                      Payment Link
                    </div>
                    <CopyText
                      styles={{
                        container: "mb-4 px-4",
                        input:
                          "w-full border border-dashed border-gray-300 rounded-tl-lg rounded-bl-lg bg-gray-100 !h-10",
                        button:
                          "bg-gray-200 text-gray-700 border-gray-200 !h-10",
                      }}
                      text={`${
                        import.meta.env.REACT_APP_API_ENDPOINT
                      }/payment/list?loanApplicationID=${loanID}`}
                    />
                  </div>
                )}
                {payments.map((payment, paymentIdx) => {
                  return (
                    <PaymentCard
                      payment={payment}
                      key={paymentIdx}
                      loanApplicationID={loanID}
                    ></PaymentCard>
                  );
                })}
              </div>
            ) : null}
          </Accordion>

          <Accordion
            title="Payment Actions"
            styles={{
              container: "block border border-gray-200 rounded-lg mb-4",
              button:
                "px-4 py-2 flex items-center justify-between w-full text-sm font-medium",
            }}
            defaultOpen={false}
          >
            {showBtn && (
              <PrivateComponent
                requiredPage={pageName!}
                sourceEntityId={sourceEntityID}
                requiredFunctions={[ACTIONS_LIST.GENERATE_EMANDATE_LINK]}
              >
                <div className="mt-8 px-4 py-2 w-max rounded-lg text-white text-sm bg-indigo-700 flex items-center justify-center font-medium">
                  <button onClick={onGenerateLinkClick}>
                    Generate E-Mandate Link
                  </button>
                </div>
              </PrivateComponent>
            )}
            {showPDCBtn && (
              <PrivateComponent
                requiredPage={pageName!}
                sourceEntityId={sourceEntityID}
                requiredFunctions={[ACTIONS_LIST.GENERATE_EMANDATE_LINK]}
              >
                <div className="mt-8 px-4 py-2 w-max rounded-lg text-white text-sm bg-indigo-700 flex items-center justify-center font-medium">
                  <button onClick={onGenerateLinkClick}>Add PDC Details</button>
                </div>
              </PrivateComponent>
            )}
          </Accordion>
        </div>
        <DialogOne
          title="Emandate Invite Link"
          open={showEMandateModal}
          setOpen={setShowEMandateModal}
        >
          <GenerateEMandateModal
            setOpen={setShowEMandateModal}
            url={eMandateLink}
          />
        </DialogOne>
      </>
    );
  } else {
    return <div className="text-sm">Payment Details will appear here.</div>;
  }
}

export default LoanPayments;
