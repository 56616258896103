import classNames from "classnames";
import { Fragment, ReactNode, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BsExclamationTriangle } from "react-icons/bs";

export interface ConfirmationDialogProp {
  open: boolean;
  icon?: ReactNode;
  description: string;
  headingText?: string;
  onConfirm: () => void;
  confirmButtonText: string;
  setOpen: (open: boolean) => void;
  styles?: {
    icon?: string;
    button?: string;
    iconContainer?: string;
  };
  children?: ReactNode;
  disableSubmit?: boolean;
  onCloseCall?: () => void;
}

const ConfirmationDialog = ({
  icon,
  open,
  styles,
  setOpen,
  onConfirm,
  description,
  confirmButtonText,
  onCloseCall,
  headingText = "Confirm",
  children,
  disableSubmit = false,
}: ConfirmationDialogProp) => {
  // const cancelButtonRef = useRef(null);

  return (
    <Transition.Root
      show={open}
      as={Fragment}
    >
      <Dialog
        as="div"
        onClose={setOpen}
        className="relative z-10"
        // initialFocus={cancelButtonRef}
      >
        <Transition.Child
          as={Fragment}
          leaveTo="opacity-0"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-100"
          leave="ease-in duration-200"
          enter="ease-out duration-300"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              leave="ease-in duration-200"
              enter="ease-out duration-300"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div
                    className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 ${
                      styles?.iconContainer || ""
                    }`}
                  >
                    {icon ? (
                      icon
                    ) : (
                      <BsExclamationTriangle
                        aria-hidden="true"
                        className={classNames(
                          "h-6 w-6 text-red-600",
                          styles?.icon || ""
                        )}
                      />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {headingText}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{description}</p>
                    </div>
                    {children && <div>{children}</div>}
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={() => {
                      onConfirm();
                      setOpen(false);
                    }}
                    disabled={disableSubmit}
                    className={classNames(
                      "inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm",
                      styles?.button || "",
                      disableSubmit
                        ? "disabled:opacity-75 cursor-not-allowed"
                        : ""
                    )}
                  >
                    {confirmButtonText}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setOpen(false);
                      onCloseCall?.();
                    }}
                    className="mt-3 ouline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationDialog;
