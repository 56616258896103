import LZString from "lz-string";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";

import App from "./shared/App";
import "./assets/styles/index.css";
import packageJSON from "../package.json";

window.lzstring = LZString;
function NoStorageEnabled() {
  return (
    <div className="mx-4 py-10 leading-8">
      <p className="text-center text-sm">
        Please enable Device Storage Access to continue using <br />
        <span className="font-semibold">FinBox Platform Dashboard</span>
      </p>
    </div>
  );
}

const canRenderApp = !!window.localStorage;

if (import.meta.env.REACT_APP_ENV === "PROD") {
  Sentry.init({
    dsn: "https://f20cfe93bf1a44e1893cb52469b6fdb4@o82232.ingest.sentry.io/6366422",
    environment: "NEW_PLATFORM_" + import.meta.env.REACT_APP_ENV,
    release: packageJSON.name + "@" + packageJSON.version,
    ignoreErrors: [
      "Non-Error promise rejection captured",
      "error loading dynamically imported module",
    ],
    beforeSend: (event) => {
      if (
        import.meta.env?.REACT_APP_SENTRY_ALLOWED_HOSTNAMES?.includes(
          window.location.hostname
        )
      ) {
        return event;
      } else {
        return null;
      }
    },
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: import.meta.env.REACT_APP_ENV === "PROD",
      }),
    ],
  });
}

if (canRenderApp) {
  ReactDOM.createRoot(document.getElementById("root")).render(<App />);
} else {
  ReactDOM.createRoot(document.getElementById("root")).render(
    <NoStorageEnabled />
  );
}

const DOMAIN_SUPPORT = [
  "finbox-platform-dashboard-uat-10.s3-website.ap-south-1.amazonaws.com",
  "adityabirla-platform.finbox.in",
];

if (DOMAIN_SUPPORT?.includes(window?.location?.host)) {
  document.title = "Aditya Birla Capital";
  const favicons = document.querySelectorAll('link[rel="icon"]');
  favicons.forEach((link) => {
    document.head.removeChild(link);
  });
  const link = document.createElement("link");
  link.id = "dynamic-favicon";
  link.rel = "icon";
  link.href = "/abfl-fav.png";
  document.head.appendChild(link);
}
