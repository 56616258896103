import { useForm } from "react-hook-form";

import Button from "../../atomic/Button";
import Input from "../FormElements/Input";
import { notify } from "../../../shared/context/AlertContext";
import GetAuthContext from "../../../shared/context/AuthContext";
import { LoanApplicationService } from "../../../services/LoanApplication";

interface IProps {
  setOpen: Function;
  reload: Function;
  loanApplicationID: string;
}
export default function DisqualifyLoanApplicationModal({
  setOpen,
  reload,
  loanApplicationID,
}: IProps) {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const { fetchToken } = GetAuthContext();

  async function onSubmit(formData) {
    const { reason } = formData;
    const response = await LoanApplicationService.rejectLoan({
      loanApplicationID,
      reason: reason,
      token: fetchToken(),
    });

    if (response.status) {
      setOpen(false);
      reload();
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <Input
          register={register("reason", {
            required: true,
          })}
          label="Reason for Rejection"
          styles={{
            input: "border border-gray-300",
          }}
          error={errors.reason}
          errorText={errors?.reason?.type === "required" ? "required" : ""}
        />
      </fieldset>
      <fieldset className="flex items-center justify-end mt-6 mb-1">
        <Button
          type="submit"
          color="primary"
        >
          Confirm
        </Button>
      </fieldset>
    </form>
  );
}
