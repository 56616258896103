import { defer } from "react-router";
import { LoanApplicationService } from "../../services/LoanApplication";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export default async function loanKYCLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID } = params;
  if (loanID) {
    const token = getToken();
    const kycResponse = await LoanApplicationService.getLoanKYC({
      loanApplicationID: loanID,
      token,
      pageName,
    });
    const kycDetailsResponse = await LoanApplicationService.getKYCDetails({
      loanApplicationID: loanID,
      token,
      pageName,
    });
    const coApplicantsKYCResponse =
      await LoanApplicationService.getCoApplicantsLoanKYC({
        loanApplicationID: loanID,
        token,
        pageName,
      });
    return defer({
      kycResponse,
      kycDetailsResponse,
      coApplicantsKYCResponse,
    });
  }
}
