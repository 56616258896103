import Button from "../../atomic/Button";
import { LeadsService } from "../../../services/Leads";
import { notify } from "../../../shared/context/AlertContext";
import GetAuthContext from "../../../shared/context/AuthContext";
import GetMixpanelContext from "../../../shared/context/MixpanelContext";
import GetLeadsContext from "../../../pages/LeadDetails/states/LeadsContext";

interface IProps {
  setOpen: Function;
  reload: Function;
  userID: string;
}
export default function SkipBankConnectModal({
  userID,
  setOpen,
  reload,
}: IProps) {
  const { fetchToken } = GetAuthContext();
  const { trackUserAction } = GetMixpanelContext();
  const { pageName } = GetLeadsContext();

  async function onSubmit() {
    const response = await LeadsService.skipBankConnect({
      userID,
      token: fetchToken(),
      pageName,
    });

    if (response.status) {
      trackUserAction("skip_bank_connect", {
        userID,
      });
      notify({
        title: "Success",
        text: "Bank Connect Skipped",
        type: "success",
      });
      setOpen(false);
      reload();
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  }

  return (
    <form>
      <fieldset className="flex items-center justify-end">
        <button
          className="text-sm mr-4 px-4 py-2 w-auto rounded-lg bg-white border border-gray-300 text-gray-700 flex items-center justify-center font-medium"
          onClick={() => setOpen(false)}
        >
          No
        </button>
        <Button
          color="primary"
          className="text-sm px-4 py-2 w-auto rounded-lg text-white flex items-center justify-center font-medium"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onSubmit();
          }}
        >
          Yes, Skip Bank Connect
        </Button>
      </fieldset>
    </form>
  );
}
