import React, { ButtonHTMLAttributes, ReactNode } from "react";
import classNames from "classnames";
import { useOutletContext } from "react-router";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  size?: "large" | "default";
  color?: "primary" | "default";
  outline?: boolean;
  isLoading?: boolean;
  loadingClass?: string;
}

export default function Button({
  children,
  size = "default",
  color = "default",
  outline = false,
  onClick,
  type = "button",
  className,
  disabled,
  isLoading,
  loadingClass,
  ...rest
}: IProps) {
  const outletContxt = useOutletContext() as any;
  const { whiteLabelStyle } = outletContxt ?? {};

  const background = whiteLabelStyle?.theme?.color?.bg || "bg-indigo-600";
  const border = whiteLabelStyle?.theme?.color?.border || "border-indigo-600";
  const text = whiteLabelStyle?.theme?.color?.text || "!text-indigo-600";
  const primaryBtn = `${background} text-white ${border}`;
  const outlinePrimary = `border !bg-[#ffffff] ${text}`;

  return (
    <button
      type={type}
      className={classNames(
        "px-4 font-medium leading-normal rounded-md text-sm",
        {
          "py-2": size === "default",
          "py-3": size === "large",
          "bg-gray-300 text-gray-800 border-gray-300": color === "default",
          [primaryBtn]: color === "primary",
          [outlinePrimary]: outline && color === "primary",
          "border !bg-white !text-gray-600": outline && color === "default",
          "border-none": !outline,
          "cursor-pointer opacity-100": !disabled,
          "cursor-not-allowed opacity-70": disabled,
        },
        className
      )}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {!!isLoading && <Loading loadingClass={loadingClass} />}
      {children}
    </button>
  );
}

export function Loading({ loadingClass = "" }: { loadingClass?: string }) {
  return (
    <div
      role="status"
      className={classNames(
        `inline-block h-4 w-4 mr-2 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`,
        loadingClass
      )}
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  );
}
