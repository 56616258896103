import React from "react";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";

import Title from "./Title";
import IntiateForm from "./IntiateForm";
import { DeviationProps } from "./interface";
import Button from "../../components/atomic/Button";
import { PAGES_LIST } from "../../shared/utils/constants";
import { notify } from "../../shared/context/AlertContext";
import GetAuthContext from "../../shared/context/AuthContext";
import { getFormattedCurrency } from "../../shared/utils/Global";
import { LoanApplicationService } from "../../services/LoanApplication";

let FIELDS = {
  // amount: {
  //   originalValue: "",
  //   label: "Loan Amount",
  //   validation: "NONNEGATIVE",
  // },
  annualInterest: {
    originalValue: "",
    validation: "ROI",
    label: "Interest Rate",
  },
  tenureMonths: {
    originalValue: "",
    validation: "MONTHS",
    label: "Tenure(Months)",
  },
  processingFee: {
    originalValue: "",
    label: "Processing Fee",
    validation: "PROCESSINGFEE",
  },
};

export default function Intiate(props: DeviationProps) {
  const {
    watch,
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const formValues = watch();
  const { loanID } = useParams();
  const { fetchToken } = GetAuthContext();
  const loanOffer = props?.loanOfferDetails?.[0];

  Object.keys(FIELDS ?? {})?.forEach((key: string) => {
    FIELDS[key]["originalValue"] = loanOffer[key];
    FIELDS[key]["proposedValue"] = loanOffer[key];
  });

  const onSubmitHandler = async (data: any) => {
    const payloadInfo = {
      loanApplicationID: loanID as string,
      loanOfferID: loanOffer?.loanOfferID,
      loanAmount: {
        previous: String(loanOffer?.amount ?? ""),
        proposed: String(loanOffer?.amount ?? ""),
      },
      processingFee: {
        previous: String(data?.original?.processingFee ?? ""),
        proposed: String(data?.proposed?.processingFee ?? ""),
      },
      interestRate: {
        previous: String(data?.original?.annualInterest ?? ""),
        proposed: String(data?.proposed?.annualInterest ?? ""),
      },
      tenure: {
        previous: String(data?.original?.tenureMonths ?? ""),
        proposed: String(data?.proposed?.tenureMonths ?? ""),
      },
      comments: data?.comments,
      reasonForDeviation: "",
      requestID: "",
      requestAction: "raised",
      actionByGroupName: "",
      status: "",
      currentStatus: "",
      createdAt: "",
      viewOnly: false,
      priorityGroup: "",
      nextSteps: [],
    };
    const payload = {
      token: fetchToken(),
      payload: payloadInfo,
      loanApplicationID: loanID,
      pageName: PAGES_LIST.LOAN_APPLICATION_LOAN_TERM_DEVIATION_TAB,
    };
    const response = await LoanApplicationService.saveLoanTermDeviation(
      payload
    );

    if (response?.status) {
      notify({
        type: "success",
        title: "Success",
        text: "Loan Term Deviation Intiated Proposal Successfully",
      });
      setTimeout(() => window.location.reload(), 1000);
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  const isDisabled = React.useMemo(() => {
    const { proposed = {} } = formValues;
    if (
      !proposed?.amount &&
      !proposed?.annualInterest &&
      !proposed?.processingFee &&
      !proposed?.tenureMonths
    ) {
      return true;
    }

    return false;
  }, [formValues]);

  return (
    <>
      <Title
        title="Initiate Loan Deviation"
        close={() => props?.setIsSliderOpen(false)}
      />
      <div className="text-xs text-[#4C4C4D] font-normal ">
        You can commence Proposals for all available loan terms here
      </div>
      <form
        className="mt-10"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="flex justify-between items-center border-b pb-5 mb-5">
          <div className="text-sm font-semibold text-[#626262]">
            Loan Amount
          </div>
          <div className="text-xl font-semibold">
            {getFormattedCurrency(loanOffer?.amount)}
          </div>
        </div>
        <IntiateForm
          errors={errors}
          fields={FIELDS}
          isValid={isValid}
          register={register}
          getValues={getValues}
          loanOffer={loanOffer}
          textArea={{ label: "Comments", name: "comments" }}
        />
        <div className="mt-10 text-xs text-[#777878] font-normal">
          This action will submit the entire proposal for all the Loan terms
        </div>
        <Button
          type="submit"
          color="primary"
          disabled={isDisabled}
          className=" text-white mt-2"
        >
          Submit Proposal
        </Button>
      </form>
    </>
  );
}
