import { Fragment, memo } from "react";
import { useOutletContext } from "react-router";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { MultiSelectDropdownProps } from "./interface";

function classNames(...classes: Array<string | undefined>) {
  return classes.filter(Boolean).join(" ");
}

export default memo(function SelectListbox({
  label,
  options = [],
  selected = [],
  setSelected,
  selectClassName = "",
  dropdownClassName = "",
  styles = {},
  requiredIcon = false,
  multiple = undefined,
  placeholder = "",
  disabled = false,
}: MultiSelectDropdownProps) {
  const onChange = (item: any) => {
    setSelected(item);
  };

  const outletContxt = useOutletContext() as any;
  const { whiteLabelStyle } = outletContxt ?? {};

  const buttonMultipleLabel = multiple
    ? selected?.map((item) => item.name).join(", ")
    : "";

  return (
    <Listbox
      value={selected}
      onChange={onChange}
      multiple={multiple}
      disabled={disabled}
    >
      {({ open }) => (
        <div className={styles.container || ""}>
          {label ? (
            <Listbox.Label
              className={classNames(
                "block text-xs font-medium text-gray-700 mb-2",
                styles?.label || ""
              )}
            >
              {label}{" "}
              {requiredIcon && (
                <span className="text-inherit text-red-700 ml-1">*</span>
              )}
            </Listbox.Label>
          ) : null}
          <div className="relative">
            <Listbox.Button
              className={classNames(
                "relative w-full cursor-default rounded-md border border-gray-200 bg-white py-2 pl-4 pr-10 text-left focus:border-indigo-500 focus:outline-none text-xs leading-5",
                whiteLabelStyle?.theme?.hover?.focus,
                styles.select
              )}
            >
              {multiple ? (
                <span
                  className={classNames("block truncate text-gray-700")}
                  title={buttonMultipleLabel}
                >
                  {buttonMultipleLabel || placeholder}
                </span>
              ) : (
                <span className="flex items-center">
                  {selected?.icon && (
                    <img
                      src={selected?.icon}
                      alt=""
                      className="h-6 w-6 flex-shrink-0 rounded-full"
                    />
                  )}
                  <span
                    className={classNames(
                      "block truncate text-gray-700",
                      selected?.icon ? "ml-3 " : ""
                    )}
                  >
                    {selected.name || placeholder}
                  </span>
                </span>
              )}

              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-200"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              enter="transition ease-in duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              <Listbox.Options
                className={classNames(
                  "absolute z-10 mt-1 max-h-56 w-fit overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-xs",
                  styles?.options || ""
                )}
              >
                {options?.map((option, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active
                          ? `text-white ${
                              whiteLabelStyle?.theme?.color?.bg ||
                              "bg-indigo-600"
                            }`
                          : "text-gray-700",
                        "relative cursor-pointer select-none py-2 pl-3 pr-9",
                        styles?.option || ""
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {option?.icon && (
                            <img
                              src={option?.icon}
                              alt=""
                              className="h-6 w-6 flex-shrink-0 rounded-full"
                            />
                          )}
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                            title={option.name}
                          >
                            {option.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active
                                ? "text-white"
                                : whiteLabelStyle?.theme?.color?.text ||
                                    "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center px-2"
                            )}
                          >
                            <CheckIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
});
