import { IAuthService } from "./authService";
import { Result } from "../../shared/core/Result";
import { Post } from "../../shared/services/Fetch";
import { left, right } from "../../shared/core/Either";
import { BaseAPI } from "../../shared/services/BaseAPI";
import { noHeadersResponse } from "../../shared/utils/Global";
import { APIResponse } from "../../shared/services/APIResponse";
import { ServiceMethodArgs } from "../LoanApplication/interfaces";
import { transformDTOToQueryParamString } from "../../shared/utils/APIUtil";
import {
  GetAllTransactionsRequestDTO,
  GetAllTransactionsResponseDTO,
  GetTransactionBySearchQueryRequestDTO,
} from "../../dtos/CreditLineDTO";

export interface ICreditLineService {
  getAllTransactions(
    dto: GetAllTransactionsRequestDTO
  ): Promise<APIResponse<GetAllTransactionsResponseDTO>>;
  getTransactionBySearchQuery(
    dto: GetTransactionBySearchQueryRequestDTO
  ): Promise<APIResponse<GetAllTransactionsResponseDTO>>;
}

export class CreditLineService extends BaseAPI implements ICreditLineService {
  constructor(authService: IAuthService) {
    super(authService);
  }

  async getAllTransactions(
    dto: GetAllTransactionsRequestDTO
  ): Promise<APIResponse<GetAllTransactionsResponseDTO>> {
    try {
      const response = await this.get(
        `/masterDashboard/transactions${transformDTOToQueryParamString<GetAllTransactionsRequestDTO>(
          dto
        )}`
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  async getTransactionBySearchQuery(
    dto: GetTransactionBySearchQueryRequestDTO
  ): Promise<APIResponse<GetAllTransactionsResponseDTO>> {
    try {
      const response = await this.get(
        `/masterDashboard/searchOnlyTransactions${transformDTOToQueryParamString<
          GetTransactionBySearchQueryRequestDTO | {}
        >(dto)}`
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }
  static async updateCLTxnDetails({
    token,
    sourceEntityID,
    payload,
    pageName,
  }: ServiceMethodArgs<{ payload: any; sourceEntityID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    headers.append("source-entity-id", sourceEntityID);

    const response = await Post(
      `/masterDashboard/updateCLTxnDetails`,
      token,
      payload,
      headers
    );
    return response;
  }
}
